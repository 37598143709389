import { IonSpinner } from '@ionic/react';
import { arrowBackOutline, arrowForwardOutline, downloadOutline } from 'ionicons/icons';
import { useCallback, useRef, useState } from 'react';
import { pdfjs, Document } from 'react-pdf';
import { OnDocumentLoadSuccess } from 'react-pdf/dist/cjs/shared/types';

import { useResizeObserver } from '../../hooks/useResizeObserver';
import { BaseProps } from '../../types/props';
import CircleIconButton from '../CircleIconButton';

import S from './PDFDocument.styles';
import PDFPage from './PDFPage';

interface Props extends BaseProps {
  url: string;
  fileName?: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const PDFDocument: React.FC<Props> = ({ url, fileName = 'Untitled Document', ...baseProps }) => {
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasLoaded, setHasLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [docContainerWidth, setDocContainerWidth] = useState<number>(0);
  const [docContainerHeight, setDocContainerHeight] = useState<number>(0);

  const onResize = useCallback<ResizeObserverCallback>(entries => {
    const [entry] = entries;

    if (entry) {
      setDocContainerWidth(entry.contentRect.width);
      setDocContainerHeight(entry.contentRect.height);
    }
  }, []);

  const onLoadSuccess: OnDocumentLoadSuccess = useCallback(data => {
    setNumPages(data.numPages);
    setHasLoaded(true);
  }, []);

  useResizeObserver(containerRef.current, onResize);

  return (
    <S.Container ref={containerRef} {...baseProps}>
      <S.DocumentContainer>
        <Document
          file={url}
          onLoadSuccess={onLoadSuccess}
          loading={<IonSpinner name="circular" />}
          error={<IonSpinner name="circular" />}
        >
          <PDFPage
            key={currentPage}
            pageNumber={currentPage}
            containerHeight={docContainerHeight}
            containerWidth={docContainerWidth}
          />
        </Document>

        {numPages > 1 && (
          <>
            <S.PreviousPage>
              <CircleIconButton
                size="small"
                color="darkgray-300"
                disabled={currentPage === 1}
                icon={arrowBackOutline}
                onClick={() => setCurrentPage(_currentPage => Math.max(_currentPage - 1, 1))}
              />
            </S.PreviousPage>

            <S.NextPage>
              <CircleIconButton
                size="small"
                color="darkgray-300"
                disabled={currentPage === numPages}
                icon={arrowForwardOutline}
                onClick={() => setCurrentPage(_currentPage => Math.min(_currentPage + 1, numPages))}
              />
            </S.NextPage>
          </>
        )}
      </S.DocumentContainer>

      {hasLoaded && (
        <S.ControlsContainer>
          <S.FileName>{fileName}</S.FileName>
          <S.CurrentPage>
            {currentPage} / {numPages}
          </S.CurrentPage>
          <S.Buttons>
            <CircleIconButton
              icon={downloadOutline}
              color="darkgray-300"
              href={url}
              download={fileName}
              target="_blank"
              size="small"
            />
          </S.Buttons>
        </S.ControlsContainer>
      )}
    </S.Container>
  );
};

export default PDFDocument;
