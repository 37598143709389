import { css } from 'styled-components';

import { from, to } from '../breakpoints';

import { textDarkGradient } from './gradient';

const generateClassName = (classNameBase: string, value: string) => {
  let className = `.${classNameBase} { ${value} !important; }`;

  Object.entries(from).forEach(([breakpoint, mediaQuery]) => {
    className += `
      ${mediaQuery} {
        .${classNameBase}-from-${breakpoint} { ${value} !important; }
      }
    `;
  });

  Object.entries(to).forEach(([breakpoint, mediaQuery]) => {
    className += `
      ${mediaQuery} {
        .${classNameBase}-to-${breakpoint} { ${value} !important; }
      }
    `;
  });

  return className;
};

const generatePaddingMarginUtils = () => {
  let styles = '';

  styles += `
    .mt-auto { margin-top: auto !important; }
    .mr-auto { margin-right: auto !important; }
    .mb-auto { margin-bottom: auto !important; }
    .ml-auto { margin-left: auto !important; }
  `;

  for (let i = -64; i <= 64; i++) {
    styles += `
      .pt-${i} { padding-top: ${i}px !important; }
      .pr-${i} { padding-right: ${i}px !important; }
      .pb-${i} { padding-bottom: ${i}px !important; }
      .pl-${i} { padding-left: ${i}px !important; }
      .mt-${i} { margin-top: ${i}px !important; }
      .mr-${i} { margin-right: ${i}px !important; }
      .mb-${i} { margin-bottom: ${i}px !important; }
      .ml-${i} { margin-left: ${i}px !important; }
    `;
  }

  for (let i = -10; i <= 10; i++) {
    styles += `
      .pt-${i}rem { padding-top: ${i}rem !important; }
      .pr-${i}rem { padding-right: ${i}rem !important; }
      .pb-${i}rem { padding-bottom: ${i}rem !important; }
      .pl-${i}rem { padding-left: ${i}rem !important; }
      .mt-${i}rem { margin-top: ${i}rem !important; }
      .mr-${i}rem { margin-right: ${i}rem !important; }
      .mb-${i}rem { margin-bottom: ${i}rem !important; }
      .ml-${i}rem { margin-left: ${i}rem !important; }
    `;
  }

  return styles;
};

const classNamesCss = css`
  ${generatePaddingMarginUtils()}

  ${generateClassName('d-none', 'display: none')}
  ${generateClassName('d-flex', 'display: flex')}
  ${generateClassName('d-block', 'display: block')}
  ${generateClassName('d-grid', 'display: grid')}
  ${generateClassName('d-initial', 'display: initial')}

  .hidden {
    visibility: hidden;
  }

  .text-dark-gradient {
    ${textDarkGradient}
  }
`;

export default classNamesCss;
