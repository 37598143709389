import { css } from 'styled-components';

export const textDarkGradient = css`
  background: linear-gradient(
    155deg,
    rgb(var(--figma-color-ocean-8-rgb)) 0%,
    var(--figma-color-ocean-200) 140%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
