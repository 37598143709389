import { css } from 'styled-components';

export const skeletonBg = css`
  @keyframes skeleton {
    from {
      background-position: -400px 0px;
    }
    to {
      background-position: 400px 0px;
    }
  }

  background-color: rgba(0, 0, 0, 0) !important;
  background-image: linear-gradient(
    to right,
    rgba(var(--skeleton-background, var(--figma-color-darkgray-300-rgb)), 0.067) 8%,
    rgba(var(--skeleton-background, var(--figma-color-darkgray-300-rgb)), 0.133) 18%,
    rgba(var(--skeleton-background, var(--figma-color-darkgray-300-rgb)), 0.067) 33%
  ) !important;
  background-repeat: repeat !important;
  background-position: -126.72px 0px !important;
  background-size: 800px 104px !important;
  animation: 1s infinite forwards skeleton !important;
`;
