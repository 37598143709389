import { TDocumentStatus } from '../types/document';

export const getDocumentStatus = (status: TDocumentStatus): string => {
  switch (status) {
  case 'NEW':
    return 'Uploaded';
  case 'PROCESSING':
    return 'Processing';
  case 'PROCESSED':
    return 'Processed';
  case 'FAILED':
    return 'Failed';
  case 'DELETING':
    return 'Deleting';
  case 'DELETED':
    return 'Deleted';
  default:
    return 'Unkown';
  }
};
