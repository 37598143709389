import { Redirect, Route, RouteProps } from 'react-router-dom';

import { Routes } from '../config/routes';
import { useGetIsRouteAccessible } from '../hooks/useGetIsRouteAccessible';
import { useHomeRoute } from '../hooks/useHomeRoute';

interface Props extends RouteProps {
  path: Routes;
}

const ConditionalRoute: React.FC<Props> = ({ path, exact, ...routeProps }) => {
  const getIsRouteAccessible = useGetIsRouteAccessible();
  const homeRoute = useHomeRoute();

  if (getIsRouteAccessible(path)) return <Route path={path} exact={exact} {...routeProps} />;

  return (
    <Route path={path} exact={exact}>
      <Redirect
        to={{
          pathname: homeRoute,
        }}
      />
    </Route>
  );
};

export default ConditionalRoute;
