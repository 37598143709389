import { css } from 'styled-components';

import { textDarkGradient } from './utils/gradient';

const base = css`
  margin: 0 0 1em 0;
`;

export const p = css`
  ${base}

  font-family: 'Matter', sans-serif;
  font-size: 1rem;
`;

export const h1 = css`
  ${base}

  font-family: 'Matter', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 2.75rem;
  line-height: 1;
  letter-spacing: -0.02em;
  font-feature-settings: 'case' on;

  ${textDarkGradient}
`;

export const h2 = css`
  ${base}

  font-family: 'Matter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.2;
  letter-spacing: -0.03em;
  font-feature-settings: 'case' on;
`;

export const h3 = css`
  font-family: 'Matter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: -0.03em;
  font-feature-settings: 'case' on;
`;

export const subtitle = css`
  font-family: 'MatterMono', monospace;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5;
  letter-spacing: 0.06em;
  font-feature-settings: 'case' on;
  text-transform: uppercase;
`;

const typographyCss = css`
  @font-face {
    font-family: 'Matter';
    src: url('/assets/fonts/Matter-Bold.woff2') format('woff2'),
      url('/assets/fonts/Matter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Matter';
    src: url('/assets/fonts/Matter-SemiBold.woff2') format('woff2'),
      url('/assets/fonts/Matter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Matter';
    src: url('/assets/fonts/Matter-Heavy.woff2') format('woff2'),
      url('/assets/fonts/Matter-Heavy.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Matter';
    src: url('/assets/fonts/Matter-Light.woff2') format('woff2'),
      url('/assets/fonts/Matter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Matter';
    src: url('/assets/fonts/Matter-Medium.woff2') format('woff2'),
      url('/assets/fonts/Matter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Matter';
    src: url('/assets/fonts/Matter-Regular.woff2') format('woff2'),
      url('/assets/fonts/Matter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterMono';
    src: url('/assets/fonts/MatterMono-Bold.woff2') format('woff2'),
      url('/assets/fonts/MatterMono-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterMono';
    src: url('/assets/fonts/MatterMono-Regular.woff2') format('woff2'),
      url('/assets/fonts/MatterMono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterMono';
    src: url('/assets/fonts/MatterMono-SemiBold.woff2') format('woff2'),
      url('/assets/fonts/MatterMono-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterMono';
    src: url('/assets/fonts/MatterMono-Heavy.woff2') format('woff2'),
      url('/assets/fonts/MatterMono-Heavy.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterMono';
    src: url('/assets/fonts/MatterMono-Light.woff2') format('woff2'),
      url('/assets/fonts/MatterMono-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterMono';
    src: url('/assets/fonts/MatterMono-Medium.woff2') format('woff2'),
      url('/assets/fonts/MatterMono-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterSQ';
    src: url('/assets/fonts/MatterSQ-Bold.woff2') format('woff2'),
      url('/assets/fonts/MatterSQ-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterSQ';
    src: url('/assets/fonts/MatterSQ-Medium.woff2') format('woff2'),
      url('/assets/fonts/MatterSQ-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterSQ';
    src: url('/assets/fonts/MatterSQ-Regular.woff2') format('woff2'),
      url('/assets/fonts/MatterSQ-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterSQ';
    src: url('/assets/fonts/MatterSQ-SemiBold.woff2') format('woff2'),
      url('/assets/fonts/MatterSQ-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterSQ';
    src: url('/assets/fonts/MatterSQ-Heavy.woff2') format('woff2'),
      url('/assets/fonts/MatterSQ-Heavy.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterSQ';
    src: url('/assets/fonts/MatterSQ-Light.woff2') format('woff2'),
      url('/assets/fonts/MatterSQ-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterSQMono';
    src: url('/assets/fonts/MatterSQMono-Bold.woff2') format('woff2'),
      url('/assets/fonts/MatterSQMono-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterSQMono';
    src: url('/assets/fonts/MatterSQMono-Light.woff2') format('woff2'),
      url('/assets/fonts/MatterSQMono-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterSQMono';
    src: url('/assets/fonts/MatterSQMono-Medium.woff2') format('woff2'),
      url('/assets/fonts/MatterSQMono-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterSQMono';
    src: url('/assets/fonts/MatterSQMono-Regular.woff2') format('woff2'),
      url('/assets/fonts/MatterSQMono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterSQMono';
    src: url('/assets/fonts/MatterSQMono-SemiBold.woff2') format('woff2'),
      url('/assets/fonts/MatterSQMono-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'MatterSQMono';
    src: url('/assets/fonts/MatterSQMono-Heavy.woff2') format('woff2'),
      url('/assets/fonts/MatterSQMono-Heavy.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    ${base}
  }

  h1 {
    ${h1}
  }

  h2 {
    ${h2}
  }

  h3 {
    ${h3}
  }

  p {
    ${p}
  }
`;

export default typographyCss;
