import styled from 'styled-components';

import { to } from '../styles/breakpoints';

const ContentWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-wrap: nowrap;

  ${to.md} {
    flex-wrap: wrap;
  }
`;

const Topics = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  gap: 2rem;
  justify-content: space-between;

  ${to.md} {
    gap: 1rem;
  }
`;

const TopicsHeader = styled.h3`
  margin-bottom: auto;
  margin: 0 !important;
`;

const TopicsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: baseline;
  gap: 0.25rem;

  ${to.md} {
    flex-direction: row;
  }
`;

const Divider = styled.div`
  flex: 0 0 9px;

  ${to.md} {
    display: none;
  }
`;

const TopicContent = styled.div`
  flex: 1 1 100%;
`;

export default {
  ContentWrapper,
  Topics,
  TopicsHeader,
  TopicsContainer,
  Divider,
  TopicContent,
};
