import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonRow,
  IonSkeletonText,
} from '@ionic/react';
import { documentOutline, documentsOutline } from 'ionicons/icons';

import Subtitle from '../../pages/common/Subtitle';
import { BaseProps } from '../../types/props';
import LLMChatBubble from '../stream-chat/LLMChatBubble';

const DocumentListLoadingState: React.FC<BaseProps> = baseProps => (
  <>
    <IonRow {...baseProps}>
      <IonCol size="12">
        <Subtitle icon={documentsOutline} />
        <h1>
          <IonSkeletonText animated />
        </h1>
      </IonCol>
    </IonRow>

    <IonRow>
      <IonCol size="12">
        <LLMChatBubble isFullWidth>
          <IonSkeletonText animated style={{ width: '20rem' }} />
        </LLMChatBubble>
      </IonCol>

      <IonCol size="12">
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>
              <IonSkeletonText animated style={{ width: '8rem' }} />
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            <IonSkeletonText animated style={{ width: '22rem' }} />
          </IonCardContent>
        </IonCard>
      </IonCol>
    </IonRow>

    <IonRow>
      <IonCol size="12">
        <IonCard>
          <IonList lines="full" inset>
            <IonListHeader>
              <IonNote>
                <IonSkeletonText animated style={{ width: '4rem' }} />
              </IonNote>
            </IonListHeader>

            {Array.from(Array(8)).map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <IonItem key={i}>
                <IonIcon icon={documentOutline} size="large" slot="start" />

                <IonLabel>
                  <IonSkeletonText animated />
                </IonLabel>

                <IonNote slot="end">
                  <IonSkeletonText animated style={{ width: '4rem' }} />
                </IonNote>
              </IonItem>
            ))}
          </IonList>
        </IonCard>
      </IonCol>
    </IonRow>
  </>
);

export default DocumentListLoadingState;
