import { createGlobalStyle } from 'styled-components';

import ionicCss from './ionic';
import streamChatCss from './streamChat';
import typographyCss from './typography';
import classNamesCss from './utils/classNames';
import variablesFigma from './variablesFigma';
import variablesIonic from './variablesIonic';

const GlobalStyles = createGlobalStyle`
  ${variablesFigma}
  ${variablesIonic}

  body {
    font-family: 'Matter', sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.01em;
    color: var(--figma-color-darkgray-300);
    background-color: var(--figma-color-neutral-200);
  }

  a {
    color: var(--figma-color-darkgray-300);

    &:hover {
      color: var(--figma-color-darkgray-200);
    }
  }

  #root {
    height: 100%;
  }

  ${typographyCss}
  ${ionicCss}
  ${streamChatCss}
  ${classNamesCss}
`;

export default GlobalStyles;
