import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  color: var(--str-chat__text-color);
`;

const MessageWrapper = styled.div`
  max-width: 75%;
  margin-left: auto;
  background: var(--str-chat__primary-surface-color);
  padding: 1rem;
  border-radius: 1rem;

  p {
    margin: 0 !important;
  }
`;

export default {
  Container,
  MessageWrapper,
};
