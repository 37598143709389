import { css } from 'styled-components';

/** CSS Variables from Figma designs * */
/** TODO: merge with Ionic theme * */

const variablesFigma = css`
  :root {
    --figma-color-neutral-100: #f9f5f3;
    --figma-color-neutral-100-rgb: 249, 245, 243;
    --figma-color-neutral-100-80: #f9f5f3cc;
    --figma-color-neutral-100-80-rgb: 249, 245, 243;
    --figma-color-neutral-200: #ece9e7;
    --figma-color-neutral-300: #d7d3d0;
    --figma-color-neutral-300-rgb: 215, 211, 208;
    --figma-color-darkgray-100: #6d6764;
    --figma-color-darkgray-200: #4a4a4a;
    --figma-color-darkgray-200-rgb: 74, 74, 74;
    --figma-color-darkgray-300: #282625;
    --figma-color-darkgray-300-rgb: 40, 38, 37;
    --figma-color-darkgray-300-80: #282625cc;
    --figma-color-darkgray-300-rgb: 40, 38, 37;
    --figma-color-ocean-4: #1b303c0a;
    --figma-color-ocean-8: #1b303c14;
    --figma-color-ocean-8-rgb: 27, 48, 60;
    --figma-color-ocean-16: #1b303c29;
    --figma-color-ocean-16-rgb: 27, 48, 60;
    --figma-color-ocean-100: #78b09c;
    --figma-color-ocean-200: #589780;
    --figma-color-ocean-300: #245966;
    --figma-color-ocean-400: #1b353c;
    --figma-color-ocean-400-rgb: 27, 53, 60;
    --figma-color-blue-100: #5c9fd6;
    --figma-color-blue-200: #3974b1;
    --figma-color-blue-300: #234f76;
    --figma-color-blue-400: #122b44;
    --figma-color-purple-100: #8e89dc;
    --figma-color-purple-200: #635dc0;
    --figma-color-purple-300: #433f77;
    --figma-color-purple-400: #29273f;
    --figma-color-ocher-100: #d4aa7d;
    --figma-color-ocher-200: #b57945;
    --figma-color-ocher-300: #5f4b4b;
    --figma-color-ocher-400: #423333;
    --figma-color-red: #a81b2b;
    --figma-color-green: #1ba85b;
    --figma-color-black: #000000;
    --figma-color-white: #ffffff;
    --figma-color-white-rgb: 255, 255, 255;

    --figma-border-radius-button: 9999px;
    --figma-border-radius-card: 1rem;
    --figma-border-width-button: 1.5px;
  }
`;

export default variablesFigma;
