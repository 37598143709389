// eslint-disable-next-line import/order
import styled from 'styled-components';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { subtitle } from '../../styles/typography';
import { getStyledScrollbar } from '../../styles/utils/scrollbar';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DocumentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  flex: 1 1 100%;

  ${getStyledScrollbar('dark')}

  .react-pdf__Document {
    margin: auto auto;
  }

  .react-pdf__Page[style] {
    background: transparent !important;
    display: flex;
  }

  .react-pdf__Page__canvas {
    margin: auto auto;
  }

  .react-pdf__Page__textContent {
    margin: auto auto;
  }

  .react-pdf__Page__annotations {
    margin: auto auto;
  }
`;

const PreviousPage = styled.div`
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
  z-index: 2;
`;

const NextPage = styled.div`
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  z-index: 2;
`;

const ControlsContainer = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
  gap: 2rem;
  padding: 0 1.5rem;
  border-radius: 2rem;
  background-color: var(--figma-color-darkgray-300-80);
  color: var(--figma-color-neutral-100);
  flex: 0 0 3rem;
`;

const FileName = styled.div`
  ${subtitle}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CurrentPage = styled.div`
  ${subtitle}
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;

  ion-button {
    margin: 0;
  }
`;

export default {
  Container,
  DocumentContainer,
  ControlsContainer,
  PreviousPage,
  NextPage,
  FileName,
  CurrentPage,
  Buttons,
};
