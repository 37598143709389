import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo } from 'react';
import { Channel, DefaultGenerics, StreamChat } from 'stream-chat';

import { environment } from '@env';

import { useAuth } from '../context/AuthProvider';

interface Options {
  channelNamePrefix: string;
  channelNameId: string | undefined;
}

export type UseStreamChat =
  | {
      channel: Channel<DefaultGenerics>;
      client: StreamChat<DefaultGenerics>;
      isLoading: false;
    }
  | {
      channel: null;
      client: null;
      isLoading: true;
    };

const useStreamChat = (options: Options): UseStreamChat => {
  const { user, session } = useAuth();

  const fetchChatTokenQuery = useQuery({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'chatToken', session?.access_token],
    gcTime: 0,
    queryFn: async () => {
      const response = await axios.get<{
        tokenOrProvider: string;
      }>(`${environment.api}/auth/chat-token`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      return response.data.tokenOrProvider;
    },
  });

  const initChatClient = useQuery({
    enabled: !!fetchChatTokenQuery.data && !!options.channelNameId && !!user?.id,
    queryKey: ['client.connectUser', fetchChatTokenQuery.data, user?.id],
    gcTime: 0,
    queryFn: async () => {
      let didUserConnectInterrupt = false;
      const client = new StreamChat<DefaultGenerics>(environment.streamChatApiKey, undefined);

      try {
        await client.connectUser({ id: user!.id }, fetchChatTokenQuery.data);

        if (didUserConnectInterrupt) return null;

        return client;
      } catch (err) {
        didUserConnectInterrupt = true;

        return null;
      }
    },
  });

  const channel = useMemo(() => {
    if (!user?.id) return null;

    if (!initChatClient.data) return null;

    if (!options.channelNameId) return null;

    const channelName = `${options.channelNamePrefix}${options.channelNameId}`;

    return initChatClient.data.channel('messaging', channelName, {
      name: 'Custom Channel',
      image: 'https://example.com/channel-image.jpg',
      replies: false,
      disable_notifications: true,
    });
  }, [user?.id, initChatClient.data, options.channelNamePrefix, options.channelNameId]);

  return useMemo(() => {
    if (!channel || !initChatClient.data) {
      return {
        channel: null,
        client: null,
        isLoading: true,
      };
    }

    return {
      channel,
      client: initChatClient.data,
      isLoading: false,
    };
  }, [channel, initChatClient.data]);
};

export default useStreamChat;
