import { IonSpinner } from '@ionic/react';
import React, { useEffect, useMemo, useState } from 'react';

import { useAuth } from '../../context/AuthProvider';
import { useAskQuestionFromLocationState } from '../../hooks/useAskQuestionFromLocationState';
import useStreamChat from '../../hooks/useStreamChat';
import { CenteredContent } from '../../pages/common/layouts.styles';
import { Address } from '../../types/address';
import { BaseProps } from '../../types/props';

import BeforeMessageInput from './BeforeMessageInput';
import BeforeMessageList from './BeforeMessageList';
import S from './MainChat.styles';

const MESSAGE_LIMIT = 5;

interface Props extends BaseProps {
  address: Address;
}

const MainChat: React.FC<Props> = ({ address, ...baseProps }) => {
  const { user } = useAuth();

  const { channel, client, isLoading } = useStreamChat({
    channelNamePrefix: 'user-channel_',
    channelNameId: user?.id,
  });

  const [manualMessageCount, setManualMessageCount] = useState(0);
  useEffect(() => {
    if (!channel) return;

    const updateMessageCount = () => {
      const count = channel.state.messages.filter(msg => msg.user?.role === 'user').length;
      setManualMessageCount(count);
    };

    const intervalId = setInterval(updateMessageCount, 1000);

    updateMessageCount();

    return () => clearInterval(intervalId);
  }, [channel]);

  const hasReachedMessageLimit = useMemo(() => {
    if (!user) return true;

    if (!user.is_anonymous) return false;

    return manualMessageCount > MESSAGE_LIMIT;
  }, [user, manualMessageCount]);

  useAskQuestionFromLocationState(channel, !hasReachedMessageLimit);

  if (isLoading) {
    return (
      <CenteredContent>
        <IonSpinner name="circular" />
      </CenteredContent>
    );
  }

  return (
    <S.StreamChat
      client={client}
      channel={channel}
      isLoading={isLoading}
      isMessageInputDisabled={hasReachedMessageLimit}
      BeforeMessageListSlot={
        <BeforeMessageList
          address={address}
          isAnonymousUser={!!user?.is_anonymous}
          channel={channel}
        />
      }
      AfterMessageListSlot={<BeforeMessageInput hasReachedMessageLimit={hasReachedMessageLimit} />}
    />
  );
};

export default MainChat;
