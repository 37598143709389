import styled from 'styled-components';

import { to } from '../../styles/breakpoints';

import PropertyCardStyles from './PropertyCard.styles';

const CardsGrid = styled.div`
  display: grid;
  min-width: 320px;
  grid-template-columns: repeat(4, 12rem);
  grid-template-rows: repeat(4, 7.5rem);
  grid-template-areas:
    'address address details buildingSize'
    'address address details propertySize'
    'address address price tax'
    'address address price tax';
  gap: 1rem;

  ${to.md} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas:
      'address address details buildingSize'
      'address address details propertySize'
      'address address price tax'
      'address address price tax';
  }

  ${to.xs} {
    grid-template-columns: minmax(18rem, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      'address'
      'address'
      'details'
      'buildingSize'
      'propertySize'
      'price'
      'tax';
  }

  ${PropertyCardStyles.IonCard} {
    &:nth-child(1) {
      grid-area: address;
    }

    &:nth-child(2) {
      grid-area: details;
    }

    &:nth-child(3) {
      grid-area: buildingSize;
    }

    &:nth-child(4) {
      grid-area: propertySize;
    }

    &:nth-child(5) {
      grid-area: price;
    }

    &:nth-child(6) {
      grid-area: tax;
    }
  }
`;

export default {
  CardsGrid,
};
