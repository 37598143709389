import React from 'react';
import { Link } from 'react-router-dom';
import { Channel } from 'stream-chat';

import { Routes } from '../../config/routes';
import { Address } from '../../types/address';
import { BaseProps } from '../../types/props';
import { addressToText } from '../../utils/address';
import LLMChatBubble from '../stream-chat/LLMChatBubble';
import StarterQuestions from '../stream-chat/StarterQuestions';
import UserChatBubble from '../stream-chat/UserChatBubble';

interface Props extends BaseProps {
  address: Address;
  isAnonymousUser: boolean;
  channel: Channel;
}

const BeforeMessageList: React.FC<Props> = ({ address, isAnonymousUser, channel }) => (
  <>
    <h1 className="mt-7rem">
      Hi there, I'm Terri!
      <br />
      Your go-to guide for everything in your community.
      <br />
      Ask me a question. I'm here to help!
    </h1>

    <LLMChatBubble>
      For personalized information, enter your address. Your data stays private—we never share it.
      Enjoy exploring!
    </LLMChatBubble>

    <div className="str-chat__list">
      <UserChatBubble>{addressToText(address)}</UserChatBubble>
    </div>

    {isAnonymousUser && (
      <LLMChatBubble>
        To save your information, <Link to={Routes.signup}>create an account</Link>.
      </LLMChatBubble>
    )}

    <StarterQuestions
      channel={channel}
      questions={[
        'Where can I make my sewer bill payment?',
        'What are the bus routes in Princeton?',
        'What is the contact number for animal control?',
        'What are the average property taxes in Princeton?',
      ]}
    >
      <LLMChatBubble>Here are some recently asked questions to get you started:</LLMChatBubble>
    </StarterQuestions>
  </>
);

export default BeforeMessageList;
