import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Routes } from '../../config/routes';
import { useAuth } from '../../context/AuthProvider';
import { useGetIsRouteAccessible } from '../../hooks/useGetIsRouteAccessible';
import { BaseProps } from '../../types/props';

import S from './Menu.styles';

interface MenuItem {
  text: string;
  link?: string;
  shape?: string;
  fill?: string;
  isStartChat?: boolean;
  onClick?: () => void;
}

interface Props extends BaseProps {
  orientation: 'horizontal' | 'vertical';
}

const Menu: React.FC<Props> = ({ orientation, ...baseProps }) => {
  const location = useLocation();
  const { user } = useAuth();
  const getIsRouteAccessible = useGetIsRouteAccessible();

  const menuItems: MenuItem[] = useMemo(() => {
    if (!user || user.is_anonymous) {
      return [
        { text: 'About Us', link: Routes.aboutUs },
        { text: 'Login', link: Routes.login },
        { text: 'Start a Chat', link: Routes.chat, isStartChat: true },
      ].filter(menuItem => getIsRouteAccessible(menuItem.link));
    }

    return [
      { text: 'Dashboard', link: Routes.dashboard },
      { text: 'Documents', link: Routes.documentList },
      { text: 'Chat', link: Routes.chat },
      { text: 'Logout', link: Routes.logout },
    ].filter(menuItem => getIsRouteAccessible(menuItem.link));
  }, [user, getIsRouteAccessible]);

  return menuItems.map((item, index) => (
    <S.IonButton
      key={item.text}
      $isActive={item.link === location.pathname && location.pathname !== Routes.chat}
      $isStartChat={item.isStartChat}
      routerLink={item.link}
      fill="clear"
      shape="round"
      expand={orientation === 'vertical' ? 'full' : undefined}
      onClick={item.onClick}
      {...baseProps}
    >
      {item.text}
    </S.IonButton>
  ));
};

export default Menu;
