import { IonImg } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
  chatbubblesOutline,
  chevronCollapseOutline,
  closeOutline,
  documentTextOutline,
} from 'ionicons/icons';
import { useState } from 'react';
import { Redirect, useLocation, useHistory, useParams } from 'react-router-dom';

import { environment } from '@env';

import CircleIconButton from '../components/CircleIconButton';
import DocumentChat from '../components/document/DocumentChat';
import DocumentSummary from '../components/document/DocumentSummary';
import PDFDocument from '../components/document/PDFDocument';
import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';
import { TDocument } from '../types/document';

import { CenteredContent } from './common/layouts.styles';
import S from './DocumentViewPage.styles';

// eslint-disable-next-line no-shadow
enum Section {
  chat,
  pdf,
  summary,
}

const DocumentViewPage: React.FC = () => {
  const { session } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [isSummaryCollapsed, setIsSummaryCollapsed] = useState(false);
  const [activeOnMobile, setActiveOnMobile] = useState<Section>(Section.pdf);
  const location = useLocation();
  const history = useHistory();

  const documentUrl = `${environment.api}/document/doc/${id}?token=${session?.access_token}`;

  const fetchDocumentQuery = useQuery<TDocument>({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'document', id, session?.access_token],
    queryFn: async () => {
      const response = await axios.get(`${environment.api}/document/${id}`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      return response.data;
    },
  });

  const isImage = fetchDocumentQuery.data?.mime_type.startsWith('image/');

  if (fetchDocumentQuery.isError)
    return (
      <Redirect
        to={{
          pathname: Routes.documentList,
          state: {
            from: location,
          },
        }}
      />
    );

  const handleOnMobileClose = () => {
    if (activeOnMobile === Section.pdf) return history.push(Routes.documentList);

    return setActiveOnMobile(Section.pdf);
  };

  return (
    <S.FullHeightPage background="darkgray-300-80">
      {isSummaryCollapsed && (
        <S.ExtendSummaryButtonMenu>
          <CircleIconButton
            icon={documentTextOutline}
            color="darkgray-300"
            onClick={() => setIsSummaryCollapsed(false)}
          />
        </S.ExtendSummaryButtonMenu>
      )}

      <S.CloseButtonMenu>
        <CircleIconButton
          icon={closeOutline}
          color="darkgray-300"
          routerLink={Routes.documentList}
        />
      </S.CloseButtonMenu>

      <S.MobileTopMenu>
        {activeOnMobile === Section.pdf && (
          <CircleIconButton
            icon={documentTextOutline}
            color="darkgray-300"
            onClick={() => setActiveOnMobile(Section.summary)}
          />
        )}

        <CircleIconButton
          icon={closeOutline}
          color="darkgray-300"
          onClick={handleOnMobileClose}
          className="ml-auto"
        />
      </S.MobileTopMenu>

      {activeOnMobile === Section.pdf && (
        <S.MobileChatButtonMenu>
          <CircleIconButton
            icon={chatbubblesOutline}
            color="darkgray-300"
            onClick={() => setActiveOnMobile(Section.chat)}
          />
        </S.MobileChatButtonMenu>
      )}

      <S.Container>
        <S.SummaryContainer
          $isActive={activeOnMobile === Section.summary}
          $isCollapsed={isSummaryCollapsed}
        >
          <DocumentSummary
            document={fetchDocumentQuery.data}
            CollapseIcon={
              <CircleIconButton
                className="d-none-to-md"
                icon={chevronCollapseOutline}
                color="darkgray-300"
                onClick={() => setIsSummaryCollapsed(true)}
              />
            }
          />
        </S.SummaryContainer>

        <S.PDFContainer $isActive={activeOnMobile === Section.pdf}>
          {isImage ? (
            <CenteredContent>
              <IonImg src={documentUrl} alt={fetchDocumentQuery.data?.original_name || id} />
            </CenteredContent>
          ) : (
            <PDFDocument
              url={documentUrl}
              fileName={fetchDocumentQuery.data?.original_name || id}
            />
          )}
        </S.PDFContainer>

        <S.ChatContainer
          $isActive={activeOnMobile === Section.chat}
          $isExtended={isSummaryCollapsed}
        >
          <DocumentChat document={fetchDocumentQuery.data} />
        </S.ChatContainer>
      </S.Container>
    </S.FullHeightPage>
  );
};

export default DocumentViewPage;
