import { IonButton, IonCol, IonRow } from '@ionic/react';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import TerraiMessageInput from '../components/stream-chat/TerraiMessageInput';
import { Topic } from '../components/TopicsCard';
import { Routes } from '../config/routes';

import { ChatRouteState } from './ChatPage';
import BasePage from './common/BasePage';
import S from './HomePage.styles';

const HomePage: React.FC = () => {
  const [question, setQuestion] = useState<string>('');
  const history = useHistory();

  const onQuestionSubmit = useCallback(
    (_question: string) => {
      history.push(Routes.chat, {
        question: _question,
        isUnauthenticatedExperience: true,
      } as ChatRouteState);
    },
    [history]
  );

  const topics: Topic[] = useMemo(
    () =>
      [
        {
          title: 'Waste',
          questions: [
            'When is my trash collection day?',
            'How do I dispose of large items or hazardous waste?',
          ],
        },
        {
          title: 'Recreation',
          questions: [
            'What are the operating hours for local parks?',
            'How do I reserve a pavilion for a family gathering?',
          ],
        },
        {
          title: 'Zoning & Property',
          questions: [
            'What are the zoning regulations for my property?',
            "How do I file a complaint about a neighboring property's condition?",
          ],
        },
        {
          title: 'Road & Transportation',
          questions: [
            "What's the schedule for snow plowing in my neighborhood?",
            'How do I report a pothole or damaged street sign?',
          ],
        },
        {
          title: 'Taxes',
          questions: ['When are property taxes due?', 'How can I view my property tax assessment?'],
        },
      ].map(_topic => ({
        title: _topic.title,
        children: (
          <S.QuestionsContainer>
            {_topic.questions.map(_question => (
              <IonButton
                key={_question}
                color="ocean-8"
                onClick={() => onQuestionSubmit(_question)}
              >
                {_question}
              </IonButton>
            ))}
          </S.QuestionsContainer>
        ),
      })),
    [onQuestionSubmit]
  );

  return (
    <BasePage>
      <IonRow>
        <IonCol
          sizeXl="6"
          sizeLg="8"
          offsetXl="3"
          offsetLg="2"
          sizeMd="10"
          offsetMd="1"
          sizeSm="12"
        >
          <h1 className="mt-7rem">
            Get Instant Answers to Questions
            <br />
            About Your Home and Community.
            <br />
            Powered by AI.
            <br />
          </h1>

          <form
            onSubmit={e => {
              e.preventDefault();
              onQuestionSubmit(question);
            }}
          >
            <TerraiMessageInput value={question} onValueChange={setQuestion} />
          </form>
        </IonCol>
      </IonRow>

      <IonRow style={{ marginTop: 'auto' }}>
        <IonCol
          sizeXl="9"
          sizeLg="10"
          offsetXl="3"
          offsetLg="2"
          sizeMd="10"
          offsetMd="1"
          sizeSm="12"
        >
          <S.TopicsCard title="Ways I can help you:" topics={topics} isAutoToggling />
        </IonCol>
      </IonRow>
    </BasePage>
  );
};

export default HomePage;
