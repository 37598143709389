import { IonButton } from '@ionic/react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 0.75rem;
  margin-top: 1rem;
  color: var(--str-chat__text-color);
`;

const AvatarContainer = styled.div`
  border-radius: 6px;
  flex: 0 0 2.5rem;
`;

const MessageContainer = styled.div`
  flex: 1 1 100%;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
`;

const MessageWrapper = styled.div<{ $isFullWidth: boolean }>`
  ${({ $isFullWidth }) =>
    !$isFullWidth &&
    css`
      max-width: 75%;
    `}

  p {
    margin: 0 !important;
  }
`;

const RatingContainer = styled.div`
  display: flex;
  margin-top: 0.25rem;
  margin-bottom: -0.5rem;
`;

const RatingIonButton = styled(IonButton)`
  margin: 0;
  margin-left: -0.5rem;

  ion-icon {
    --ion-color-base: var(--str-chat__text-color);
  }

  &::part(native) {
    background: transparent;
    font-size: 0.7em;
  }

  &:not(:first-child)&::part(native) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:not(:last-child)&::part(native) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export default {
  Container,
  AvatarContainer,
  MessageContainer,
  MessageWrapper,
  RatingContainer,
  RatingIonButton,
};
