import { IonButton } from '@ionic/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { environment } from '@env';

import AddressSelect from '../components/address/AddressSelect';
import PropertyDetails from '../components/property/PropertyDetails';
import LLMChatBubble from '../components/stream-chat/LLMChatBubble';
import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';
import { Property } from '../types/property';
import { UserProperty } from '../types/userProperty';
import { addressToText } from '../utils/address';

import FullHeightPage from './common/FullHeightPage';
import { CenteredContent } from './common/layouts.styles';
import S from './PropertyCreatePage.styled';

const PropertyCreatePage: React.FC = () => {
  const [addressText, setAddressText] = useState<string | null>(null);
  const [hasFailed, setHasFailed] = useState(false);

  const { session } = useAuth();

  const history = useHistory();

  const getPropertyQuery = useQuery({
    enabled: !!session?.access_token && !!addressText,
    queryKey: ['GET', 'property/byaddress', addressText, session?.access_token],
    queryFn: async () => {
      setHasFailed(false);
      const response = await axios.post<Property>(
        `${environment.api}/property/byaddress?address=${addressText}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
  });

  const savePropertyMutation = useMutation({
    mutationFn: async (_addressText: string) => {
      const response = await axios.post<UserProperty>(
        `${environment.api}/user-property/byaddress?address=${_addressText}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      history.replace(Routes.dashboard);

      return response.data;
    },
  });

  useEffect(() => {
    if (!getPropertyQuery.error) return;

    setAddressText(null);
    setHasFailed(true);
  }, [getPropertyQuery.error]);

  const onDiscardClick = useCallback(() => {
    setAddressText(null);
  }, []);

  const onSaveClick = useCallback(() => {
    if (!addressText) return;

    savePropertyMutation.mutate(addressText);
  }, [savePropertyMutation, addressText]);

  return (
    <FullHeightPage background="transparent">
      {!addressText && (
        <CenteredContent>
          <S.ChatWrapper>
            <LLMChatBubble isFullWidth>
              Let&apos;s find your property! Provide a full address, including building and
              apartment numbers.
            </LLMChatBubble>

            <AddressSelect onAddressSelect={address => setAddressText(addressToText(address))} />

            {hasFailed && (
              <LLMChatBubble isFullWidth>
                I couldn&apos;t find property information for the provided address. Try a different
                one.
              </LLMChatBubble>
            )}
          </S.ChatWrapper>
        </CenteredContent>
      )}

      {!!addressText && !getPropertyQuery.isError && (
        <CenteredContent>
          <div>
            <PropertyDetails
              property={getPropertyQuery.data}
              isLoading={getPropertyQuery.isPending}
            />

            {getPropertyQuery.isSuccess && (
              <S.ButtonsWrapper>
                <IonButton color="darkgray-300" fill="outline" onClick={() => onDiscardClick()}>
                  Discard
                </IonButton>

                <IonButton
                  color="darkgray-300"
                  fill="solid"
                  disabled={savePropertyMutation.isPending}
                  onClick={() => onSaveClick()}
                >
                  Save Property
                </IonButton>
              </S.ButtonsWrapper>
            )}
          </div>
        </CenteredContent>
      )}
    </FullHeightPage>
  );
};

export default PropertyCreatePage;
