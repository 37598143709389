import React from 'react';

import { BaseProps } from '../types/props';

import S from './ContentGradient.styles';

const ContentGradient: React.FC<BaseProps> = baseProps => (
  <S.Container {...baseProps}>
    <S.Gradient />
    <S.Solid />
  </S.Container>
);

export default ContentGradient;
