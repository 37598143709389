import styled from 'styled-components';

import TopicsCard from '../../components/TopicsCard';
import TopicsCardStyles from '../../components/TopicsCard.styles';
import { from } from '../../styles/breakpoints';

const StyledTopicsCard = styled(TopicsCard)`
  --background: var(--figma-color-neutral-200);
  margin-top: 3rem;

  ${TopicsCardStyles.Divider} svg {
    color: var(--figma-color-neutral-300);
  }

  ${TopicsCardStyles.Topics} {
    ${from.lg} {
      flex: 0 0 15rem;
    }
  }
`;

export default {
  TopicsCard: StyledTopicsCard,
};
