import { IonButton } from '@ionic/react';
import styled from 'styled-components';

const StyledIonButton = styled(IonButton)`
  margin: 0;
`;

export default {
  IonButton: StyledIonButton,
};
