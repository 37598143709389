import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef } from 'react';

import { environment } from '@env';

import { BaseProps } from '../../types/props';

import S from './MapboxMap.styles';
import { pulsingDots } from './pulsingDots';

interface Props extends BaseProps {
  houseCoordinates: [number, number];
}

const MapboxMap: React.FC<Props> = ({ houseCoordinates, ...baseProps }) => {
  const mapContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    mapboxgl.accessToken = environment.mapboxApiKey;

    // Hide the container, so we don't get a screen flicker
    if (mapContainer.current) {
      mapContainer.current.style.opacity = '0';
    }

    // Initialize the map
    const map = new mapboxgl.Map({
      container: mapContainer.current!,
      style: 'mapbox://styles/mapbox/satellite-v9', // Changed to satellite view
      center: houseCoordinates,
      zoom: 19,
      pitch: 60,
      bearing: 80,
      interactive: false,
      trackResize: true,
    });

    map.on('load', () => {
      // Remove Mapbox logo
      const logoEl = mapContainer.current?.querySelector('.mapboxgl-ctrl-logo');
      if (logoEl) {
        logoEl.remove();
      }

      // Remove Mapbox attribution
      const attrEl = mapContainer.current?.querySelector('.mapboxgl-ctrl-attrib');
      if (attrEl) {
        attrEl.remove();
      }

      // Resize map so it fits the container
      map.resize();
      // And show the container after resizing
      if (mapContainer.current) {
        mapContainer.current.style.opacity = '1';
      }

      // Add a marker to highlight the coordinates
      new mapboxgl.Marker({
        color: '#FF0000',
        scale: 0.8,
      })
        .setLngLat(houseCoordinates)
        .addTo(map);

      // Add a pulsing dot effect
      const size = 80;
      const pulsingDot = pulsingDots(size, map);

      map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

      map.addSource('dot-point', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Point',
                coordinates: houseCoordinates,
              },
            },
          ],
        },
      });

      map.addLayer({
        id: 'layer-with-pulsing-dot',
        type: 'symbol',
        source: 'dot-point',
        layout: {
          'icon-image': 'pulsing-dot',
        },
      });
    });

    return () => map.remove();
  }, [houseCoordinates]);

  return <S.MapContainer ref={mapContainer} {...baseProps} />;
};

export default MapboxMap;
