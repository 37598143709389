import { IonCardContent, IonCardHeader, IonCardSubtitle, IonSkeletonText } from '@ionic/react';
import React from 'react';

import { BaseProps } from '../../types/props';

import S from './PropertyCard.styles';

interface Props extends BaseProps {
  title: React.ReactNode;
  children: React.ReactNode;
  isLoading?: boolean;
  loadingLines?: number;
}

const PropertyCard: React.FC<Props> = ({
  title,
  children,
  isLoading,
  loadingLines = 1,
  ...baseProps
}) =>
  isLoading ? (
    <S.IonCard {...baseProps}>
      <IonCardHeader>
        <IonCardSubtitle>
          <IonSkeletonText animated />
        </IonCardSubtitle>
      </IonCardHeader>

      <IonCardContent>
        {Array.from(Array(loadingLines)).map((_v, i) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <IonSkeletonText animated key={i} />
        ))}
      </IonCardContent>
    </S.IonCard>
  ) : (
    <S.IonCard {...baseProps}>
      <IonCardHeader>
        <IonCardSubtitle>{title}</IonCardSubtitle>
      </IonCardHeader>

      <IonCardContent>{children}</IonCardContent>
    </S.IonCard>
  );

export default PropertyCard;
