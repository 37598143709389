import {
  CheckboxChangeEventDetail,
  CheckboxCustomEvent,
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import { logoGoogle } from 'ionicons/icons';
import React, { useState } from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';

import ContentGradient from '../components/ContentGradient';
import Header from '../components/Header';
import Input from '../components/Input';
import LLMChatBubble from '../components/stream-chat/LLMChatBubble';
import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';
import { isAssociaSubdomain } from '../utils/communities';

import S from './AuthPage.styles';

interface AuthRouteState {
  from?: Location;
}

const getRedirectRoute = () => {
  if (isAssociaSubdomain()) {
    return Routes.documentList;
  }
  return Routes.chat;
};

const AuthPage: React.FC = () => {
  const { user, signInWithPassword, signUp } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const history = useHistory();
  const { signInWithOAuth } = useAuth();
  const location = useLocation<AuthRouteState>();

  const isSignup = location.pathname === Routes.signup;

  const oAuth = async () => {
    const redirectTo = location?.state?.from?.pathname ?? getRedirectRoute();

    await signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: window.location.href.replace(history.location.pathname, redirectTo),
      },
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);

    try {
      if (isSignup) {
        const redirectTo = location?.state?.from?.pathname ?? getRedirectRoute();
        const { data, error: signUpError } = await signUp({
          email,
          password,
          options: {
            emailRedirectTo: window.location.href.replace(history.location.pathname, redirectTo),
          },
        });
        if (signUpError) throw signUpError;
        if (!data.session) {
          setConfirmEmail(true);
        }
      } else {
        const { error: signInError } = await signInWithPassword({ email, password });
        if (signInError) throw signInError;
      }
    } catch (err: any) {
      setError(err?.message || err);
    }
  };

  const resetAuthView = () => {
    setConfirmEmail(false);
    setEmail('');
    setPassword('');
    setAgreedToTerms(false);
  };

  if (user && location?.state?.from) return <Redirect to={location.state.from} />;

  if (user && !user.is_anonymous) return <Redirect to={getRedirectRoute()} />;

  return (
    <IonPage>
      <IonContent className="login-content">
        <IonGrid>
          <IonRow>
            <IonCol>
              <Header logoOnly />
            </IonCol>
          </IonRow>
        </IonGrid>

        <S.Container>
          {confirmEmail ? (
            <S.AuthSection>
              <S.SectionContent>
                <h2>Verify your email address</h2>

                <div>
                  <p>
                    To use Terr.ai, click the verification button in the email we sent to {email}.
                    This helps keep your account secure.
                  </p>
                  <p>
                    Wrong address?{' '}
                    <Link to={Routes.login} onClick={resetAuthView}>
                      Try again
                    </Link>{' '}
                    with a different email. If you mistyped your email when signing up, create a new
                    account.
                  </p>
                </div>
              </S.SectionContent>
            </S.AuthSection>
          ) : (
            <S.AuthSection>
              <S.SectionContent>
                <h2>
                  Your Home and Community,
                  <br />
                  Instantly Answered
                </h2>

                <S.FormWrapper>
                  <form onSubmit={handleSubmit}>
                    <S.InputWrapper>
                      <Input
                        placeholder="Email"
                        type="text"
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setEmail(e.target.value ?? '')
                        }
                      />
                    </S.InputWrapper>

                    <S.PasswordInputWrapper>
                      <Input
                        placeholder="Password"
                        type="password"
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setPassword(e.target.value ?? '')
                        }
                      />
                    </S.PasswordInputWrapper>

                    {isSignup && (
                      <S.CheckboxWrapper>
                        <IonCheckbox
                          checked={agreedToTerms}
                          onIonChange={(e: CheckboxCustomEvent<CheckboxChangeEventDetail>) =>
                            setAgreedToTerms(e.detail.checked)
                          }
                        />
                        <IonLabel className="ml-10">
                          I agree to the{' '}
                          <Link to={Routes.termsOfService} target="_blank">
                            Terms and Conditions
                          </Link>
                        </IonLabel>
                      </S.CheckboxWrapper>
                    )}

                    <IonButton
                      expand="block"
                      shape="round"
                      type="submit"
                      color="darkgray-300"
                      size="large"
                      disabled={isSignup && !agreedToTerms} // Disable btn if terms aren't agreed to
                    >
                      {isSignup ? 'Sign Up' : 'Log In'}
                    </IonButton>

                    <IonButton
                      expand="block"
                      shape="round"
                      color="darkgray-300"
                      size="large"
                      onClick={oAuth}
                    >
                      <IonIcon icon={logoGoogle} slot="start" />
                      {isSignup ? 'Sign Up' : 'Sign In'} with Google
                    </IonButton>
                  </form>

                  {error && <IonText color="danger">{error}</IonText>}

                  <S.OrText>OR</S.OrText>

                  <IonButton
                    expand="block"
                    shape="round"
                    color="darkgray-300"
                    size="large"
                    routerLink={isSignup ? Routes.login : Routes.signup}
                  >
                    {isSignup ? 'Log In' : 'Sign Up'}
                  </IonButton>

                  <S.Footer>
                    <Link to={Routes.termsOfService} target="_blank">
                      Terms & Conditions
                    </Link>

                    <Link to={Routes.privacyPolicy} target="_blank">
                      Privacy Policy
                    </Link>
                  </S.Footer>
                </S.FormWrapper>
              </S.SectionContent>
            </S.AuthSection>
          )}

          <S.AboutSection>
            <ContentGradient />
            <S.SectionContent>
              <div>
                <LLMChatBubble isFullWidth>Ways I can help</LLMChatBubble>
              </div>

              <IonButton size="large" color="ocean-8" disabled>
                Where can I make my sewer bill payment?
              </IonButton>

              <IonButton size="large" color="ocean-8" disabled>
                What are the bus routes in Princeton?
              </IonButton>

              <IonButton size="large" color="ocean-8" disabled>
                What is the contact number for animal control?
              </IonButton>

              <IonButton size="large" color="ocean-8" disabled>
                What are the average property taxes in Princeton?
              </IonButton>
            </S.SectionContent>
          </S.AboutSection>
        </S.Container>
      </IonContent>
    </IonPage>
  );
};

export default AuthPage;
