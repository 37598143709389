import { IonIcon, IonItem, IonLabel, IonNote, IonSkeletonText } from '@ionic/react';
import { documentOutline } from 'ionicons/icons';

import { BaseProps } from '../../types/props';

const DocumentListItemLoadingState: React.FC<BaseProps> = baseProps => (
  <IonItem {...baseProps}>
    <IonIcon icon={documentOutline} size="large" slot="start" />

    <IonLabel>
      <IonSkeletonText animated />
    </IonLabel>

    <IonNote slot="end">
      <IonSkeletonText animated style={{ width: '5rem' }} />
    </IonNote>
  </IonItem>
);

export default DocumentListItemLoadingState;
