import { useCallback } from 'react';

import {
  anonymousUserRoutes,
  associaAuthenticatedRoutes,
  associaPublicRoutes,
  authenticatedUserRoutes,
  publicRoutes,
  Routes,
} from '../config/routes';
import { useAuth } from '../context/AuthProvider';
import { isAssociaSubdomain } from '../utils/communities';

export const useGetIsRouteAccessible = () => {
  const { user, session, shouldHaveAuthToken } = useAuth();

  return useCallback(
    (path: Routes) => {
      const isNotLoggedIn = !session && !shouldHaveAuthToken;

      if (isAssociaSubdomain())
        return isNotLoggedIn
          ? associaPublicRoutes.includes(path)
          : associaAuthenticatedRoutes.includes(path);

      if (publicRoutes.includes(path)) return true;

      if (user && user.is_anonymous) return anonymousUserRoutes.includes(path);

      if (user) return authenticatedUserRoutes.includes(path);

      return false;
    },
    [session, shouldHaveAuthToken, user]
  );
};
