import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';
import { isAssociaSubdomain } from '../utils/communities';

export const useHomeRoute = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return Routes.home;
  }

  if (isAssociaSubdomain()) {
    return Routes.documentList;
  }

  return Routes.dashboard;
};
