import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import ContentGradient from '../components/ContentGradient';
import Header from '../components/Header';
import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';

const LogoutPage: React.FC = () => {
  const { user, signOut } = useAuth();
  const history = useHistory();

  useEffect(() => {
    signOut();
  }, [signOut, history]);

  if (!user) return <Redirect to={Routes.login} />;

  return (
    <IonPage>
      <IonContent className="login-content">
        <ContentGradient />

        <IonGrid>
          <IonRow>
            <IonCol>
              <Header logoOnly />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default LogoutPage;
