import styled from 'styled-components';

import { getStyledScrollbar } from '../../styles/utils/scrollbar';
import { skeletonBg } from '../../styles/utils/skeletonBg';

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex: 0 1 100%;

  .str-chat {
    width: 100%;
    .str-chat__main-panel .str-chat__ul .str-chat__li:first-of-type {
      padding-top: 0;
    }

    .str-chat__file-input-container {
      display: none;
    }

    .str-chat__empty-channel {
      display: none;
    }

    .str-chat__message-textarea-with-emoji-picker {
      padding: 1rem 2rem !important;
    }

    .str-chat__message-textarea-container {
      border: none;
    }

    .str-chat__message-input-inner {
      background-color: var(--str-chat__on-primary-color);
      border-radius: 1.5rem;
    }

    .str-chat__list {
      ${getStyledScrollbar('light')}
    }

    .str-chat__loading-channel-header-avatar,
    .str-chat__loading-channel-header-name,
    .str-chat__loading-channel-header-info,
    .str-chat__loading-channel-message-date,
    .str-chat__loading-channel-message-send,
    .str-chat__loading-channel-message-input,
    .str-chat__loading-channel-message-avatar,
    .str-chat__loading-channel-message-sender,
    .str-chat__loading-channel-message-text {
      --skeleton-background: var(--figma-color-darkgray-300-rgb);
      ${skeletonBg}
    }

    &.str-chat__theme-dark {
      --skeleton-background: var(--figma-color-neutral-100-rgb);

      .str-chat__list {
        ${getStyledScrollbar('dark')}
      }
    }
  }
`;

const WindowContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const MessageListContainer = styled.div`
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const MessageInputContainer = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;

const MessageInputFooter = styled.div`
  font-size: 0.65rem;
  text-align: center;
  color: var(--color);
`;

export default {
  Container,
  WindowContainer,
  MessageListContainer,
  MessageInputContainer,
  MessageInputFooter,
};
