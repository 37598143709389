import { BaseProps } from '../../types/props';

import S from './UserChatBubble.styles';

interface Props extends BaseProps {
  children: React.ReactNode;
}

const UserChatBubble: React.FC<Props> = ({ children, ...baseProps }) => (
  <S.Container {...baseProps}>
    <S.MessageWrapper>{children}</S.MessageWrapper>
  </S.Container>
);

export default UserChatBubble;
