import { IonButton, IonCard, IonCardContent, IonImg } from '@ionic/react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { BaseProps } from '../types/props';

import S from './TopicsCard.styles';

export interface Topic {
  title: string;
  children: React.ReactNode;
}

interface Props extends BaseProps {
  buttonColor?: string;
  buttonSize?: 'small' | 'default' | 'large';
  color?: string;
  title?: string;
  topics: Topic[];
  isAutoToggling?: boolean;
}

const TopicsCard: React.FC<Props> = ({
  buttonColor = 'neutral-300',
  buttonSize = 'small',
  color = 'neutral-200',
  title,
  topics,
  isAutoToggling = false,
  ...baseProps
}) => {
  const intervalId = useRef<NodeJS.Timeout>();
  const [activeTopic, setActiveTopic] = useState<Topic>(topics[0]);

  useEffect(() => {
    if (!isAutoToggling) return;

    intervalId.current = setInterval(() => {
      setActiveTopic(_activeTopic => {
        const nextIndex =
          (topics.findIndex(topic => _activeTopic.title === topic.title) + 1) % topics.length;
        return topics[nextIndex];
      });
    }, 5000);

    return () => clearInterval(intervalId.current);
  }, [topics, isAutoToggling]);

  const onTopicClick = useCallback((topic: Topic) => {
    // User has interacted, so stop togglign between topics
    clearInterval(intervalId.current);

    setActiveTopic(topic);
  }, []);

  return (
    <IonCard color={color} {...baseProps}>
      <IonCardContent>
        <S.ContentWrapper>
          <S.Topics>
            {!!title && <S.TopicsHeader>{title}</S.TopicsHeader>}

            <S.TopicsContainer>
              {topics.map(topic => (
                <IonButton
                  key={topic.title}
                  size={buttonSize}
                  color={buttonColor}
                  fill={activeTopic.title === topic.title ? 'solid' : 'outline'}
                  onClick={() => onTopicClick(topic)}
                >
                  {topic.title}
                </IonButton>
              ))}
            </S.TopicsContainer>
          </S.Topics>

          <S.Divider>
            <IonImg src="/assets/images/divider.svg" />
          </S.Divider>

          <S.TopicContent>{activeTopic.children}</S.TopicContent>
        </S.ContentWrapper>
      </IonCardContent>
    </IonCard>
  );
};

export default TopicsCard;
