import styled from 'styled-components';

import { subtitle } from '../../styles/typography';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  color: var(--figma-color-ocean-300);
`;

const Subtitle = styled.div`
  ${subtitle};
`;

export default {
  Wrapper,
  Subtitle,
};
