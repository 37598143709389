import { IonButtons, IonMenuButton } from '@ionic/react';
import classNames from 'classnames';
import React from 'react';

import { BaseProps } from '../types/props';

import S from './Header.styles';
import Menu from './menu/Menu';
import TerraiLogo from './TerraiLogo';

interface HeaderProps extends BaseProps {
  logoOnly?: boolean;
}

const Header: React.FC<HeaderProps> = ({ logoOnly = false, className, style }) => (
  <S.IonHeader style={style} className={classNames('ion-no-border', className)}>
    <S.IonToolbar>
      <IonButtons slot="start" className="ion-hide-md-up">
        <IonMenuButton color="darkgray-300" />
      </IonButtons>

      <div className="ion-float-md-start ion-padding-start ion-margin-start">
        <TerraiLogo />
      </div>
      {!logoOnly && (
        <IonButtons slot="end" className="ion-hide-md-down">
          <Menu orientation="horizontal" />
        </IonButtons>
      )}
      {/* <IonTitle className={`${styles.title} ion-text-center`}>Terr.ai</IonTitle> */}
    </S.IonToolbar>
  </S.IonHeader>
);

export default Header;
