import { IonSpinner } from '@ionic/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react';
import 'stream-chat-react/dist/scss/v2/index.scss';

import { environment } from '@env';

import { useAuth } from '../../context/AuthProvider';
import { CenteredContent } from '../../pages/common/layouts.styles';
import { Address } from '../../types/address';
import { BaseProps } from '../../types/props';
import { UserProperty } from '../../types/userProperty';
import AddressSelect from '../address/AddressSelect';
import LLMChatBubble from '../stream-chat/LLMChatBubble';

import MainChat from './MainChat';
import S from './MainChatFlow.styles';

export interface Suggestion {
  place_name: string;
  text: string;
  address: string;
  context: Array<{
    id: string;
    text: string;
  }>;
}

interface Props extends BaseProps {
  shouldCreateAnonymousUser?: boolean;
}

const MainChatFlow: React.FC<Props> = ({ shouldCreateAnonymousUser, ...baseProps }) => {
  const [address, setAddress] = useState<Address | null>(null);

  const { user, session, signInAnonymously } = useAuth();

  const fetchAddressQuery = useQuery({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'address', session?.access_token],
    queryFn: async () => {
      const response = await axios.get<Address>(`${environment.api}/address`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      setAddress(response.data);

      return response.data;
    },
  });

  const fetchPropertyQuery = useQuery({
    enabled: !!session?.access_token && fetchAddressQuery.isFetched && !fetchAddressQuery.data,
    queryKey: ['GET', 'user-property', session?.access_token],
    queryFn: async () => {
      const response = await axios.get<UserProperty>(`${environment.api}/user-property`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      setAddress(response.data.Property);

      return response.data.Property;
    },
  });

  const saveAddressMutation = useMutation({
    mutationKey: ['saveAddress', session?.access_token],
    mutationFn: async ({
      addressToSave,
      accessToken,
    }: {
      addressToSave: Address;
      accessToken?: string;
    }) => {
      const response = await axios.post<Address>(`${environment.api}/address`, addressToSave, {
        headers: {
          Authorization: `Bearer ${accessToken ?? session?.access_token}`,
        },
      });

      setAddress(response.data);

      return response.data;
    },
  });

  const onAddressSelect = async (_address: Address) => {
    let accessToken = session?.access_token;

    if (shouldCreateAnonymousUser && !user) {
      const { data } = await signInAnonymously();

      accessToken = data.session?.access_token;
    }

    await saveAddressMutation.mutate({ addressToSave: _address, accessToken });
  };

  if (!user && !shouldCreateAnonymousUser) {
    return (
      <CenteredContent>
        <IonSpinner name="circular" />
      </CenteredContent>
    );
  }

  // Fetching address
  if (fetchAddressQuery.isFetching) {
    return (
      <CenteredContent>
        <IonSpinner name="circular" />
      </CenteredContent>
    );
  }

  // Fetching property
  if (fetchPropertyQuery.isFetching) {
    return (
      <CenteredContent>
        <IonSpinner name="circular" />
      </CenteredContent>
    );
  }

  // Saving address
  if (!address && saveAddressMutation.isPending) {
    return (
      <CenteredContent>
        <IonSpinner name="circular" />
      </CenteredContent>
    );
  }

  if (!address) {
    return (
      <S.Container>
        <h1 className="mt-4rem">
          Hi there, I'm Terri!
          <br />
          Your go-to guide for everything in your community.
          <br />
          Ask me a question. I'm here to help!
        </h1>

        <LLMChatBubble isFullWidth>
          For personalized information, enter your address. Provide a full address, including
          building and apartment numbers.
          <br />
          Your data stays private—we never share it. Enjoy exploring!
        </LLMChatBubble>

        <AddressSelect onAddressSelect={onAddressSelect} />
      </S.Container>
    );
  }

  return <MainChat address={address} {...baseProps} />;
};

export default MainChatFlow;
