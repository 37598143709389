import { useCallback } from 'react';
import { DefaultGenerics } from 'stream-chat';
import { Channel } from 'stream-chat/dist/types/channel';

import { BaseProps } from '../../types/props';

import S from './StarterQuestions.styles';

interface Props extends BaseProps {
  questions: string[];
  children?: React.ReactNode;
  channel: Channel<DefaultGenerics>;
}

const StarterQuestions: React.FC<Props> = ({ questions, channel, children, ...baseProps }) => {
  const sendMessage = useCallback(
    async (text: string) => {
      try {
        await channel.sendMessage({
          text,
        });
      } catch (error) {
        console.error('Error sending message:', error);
      }
    },
    [channel]
  );

  return (
    <S.Wrapper {...baseProps}>
      {children}

      {questions.map((question: string) => (
        <S.IonButton
          key={question}
          size="large"
          color="neutral-300"
          onClick={() => sendMessage(question)}
        >
          {question}
        </S.IonButton>
      ))}
    </S.Wrapper>
  );
};

export default StarterQuestions;
