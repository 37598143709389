import { IonCol } from '@ionic/react';
import styled from 'styled-components';

const StyledIonCol = styled(IonCol)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default {
  IonCol: StyledIonCol,
};
