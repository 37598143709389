import styled from 'styled-components';

const TopicContent = styled.p`
  font-size: 0.65rem;
  margin: 0;
`;

export default {
  TopicContent,
};
