import styled from 'styled-components';

import TopicsCard from '../components/TopicsCard';
import TopicsCardStyles from '../components/TopicsCard.styles';
import { from, to } from '../styles/breakpoints';

const QuestionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: baseline;
  gap: 0.25rem;

  ${to.sm} {
    align-items: stretch;
  }
`;

const StyledTopicsCard = styled(TopicsCard)`
  --background: var(--figma-color-neutral-200);
  margin-top: 3rem;

  ${TopicsCardStyles.Divider} svg {
    color: var(--figma-color-neutral-300);
  }

  ${TopicsCardStyles.Topics} {
    ${from.lg} {
      flex: 0 0 12.5rem;
    }
  }
`;

export default {
  QuestionsContainer,
  TopicsCard: StyledTopicsCard,
};
