import { IonCardContent, IonCardHeader, IonCardSubtitle } from '@ionic/react';
import React from 'react';

import useStreamChat from '../../hooks/useStreamChat';
import { TDocument } from '../../types/document';
import { BaseProps } from '../../types/props';
import StreamChat from '../stream-chat/StreamChat';

import S from './DocumentChat.styles';

interface Props extends BaseProps {
  document?: TDocument;
}

const DocumentChat: React.FC<Props> = ({ document, ...baseProps }) => {
  const { channel, client, isLoading } = useStreamChat({
    channelNamePrefix: 'document_channel_',
    channelNameId: document?.id,
  });

  return (
    <S.IonCard {...baseProps}>
      <IonCardHeader>
        <IonCardSubtitle>Chat</IonCardSubtitle>
      </IonCardHeader>

      <IonCardContent>
        <StreamChat client={client} channel={channel} isLoading={isLoading} theme="dark" />
      </IonCardContent>
    </S.IonCard>
  );
};

export default DocumentChat;
