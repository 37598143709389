import { IonCard, IonCol, IonGrid, IonList, IonListHeader, IonNote, IonRow } from '@ionic/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { documentsOutline } from 'ionicons/icons';
import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { SSE, SSEvent } from 'sse.js';

import { environment } from '@env';

import DocumentListEmptyState from '../components/document/DocumentListEmptyState';
import DocumentListItem from '../components/document/DocumentListItem';
import DocumentListItemLoadingState from '../components/document/DocumentListItemLoadingState';
import DocumentListLoadingState from '../components/document/DocumentListLoadingState';
import DocumentUploadCard from '../components/document/DocumentUploadCard';
import LLMChatBubble from '../components/stream-chat/LLMChatBubble';
import TabItem from '../components/tabs/TabItem';
import Tabs from '../components/tabs/Tabs';
import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';
import { TDocument, TDocumentStatus } from '../types/document';
import { formatShortDayMonthYearDate, formatShortMonthYearDate } from '../utils/date';

import FullHeightPage from './common/FullHeightPage';
import Subtitle from './common/Subtitle';
import S from './DocumentListPage.styles';

// eslint-disable-next-line no-shadow
enum GroupBy {
  Day,
  Month,
}

const DocumentListPage: React.FC = () => {
  const { session } = useAuth();
  const location = useLocation();
  const [documentStatuses, setDocumentStatuses] = useState<{
    [documentId: string]: TDocumentStatus;
  }>({});
  const [documentProgress, setDocumentProgress] = useState<{ [documentId: string]: number }>({});
  const [groupBy, setGroupBy] = useState<GroupBy>(GroupBy.Month);

  const sseClientRef = useRef<SSE | null>(null);
  const queryClient = useQueryClient();

  const fetchDocumentsQuery = useQuery({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'document', 'all', session?.access_token],
    queryFn: async () => {
      const response = await axios.get<TDocument[]>(`${environment.api}/document/all`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });
      return response.data;
    },
  });

  const updateDocumentList = useCallback(
    (updater: (_documents: TDocument[]) => TDocument[]) => {
      queryClient.setQueryData<TDocument[]>(
        ['GET', 'document', 'all', session?.access_token],
        _documents => updater(_documents ?? [])
      );
    },
    [queryClient, session?.access_token]
  );

  const onDocumentDeleted = useCallback(
    (document: TDocument) => {
      updateDocumentList(_documents =>
        _documents?.filter(_document => _document.id !== document.id)
      );
    },
    [updateDocumentList]
  );

  const uploadDocumentMutation = useMutation({
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      const response = await axios.post<TDocument>(`${environment.api}/document/upload`, formData, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      const document: TDocument = {
        ...response.data,
        status: 'PROCESSING',
        progress: 0,
      };

      updateDocumentList(_documents => [document, ..._documents]);

      return document;
    },
  });

  const onFileSelect = useCallback(
    (file: File) => {
      uploadDocumentMutation.mutate(file);
    },
    [uploadDocumentMutation]
  );

  const documents = useMemo(
    () =>
      fetchDocumentsQuery.data?.map(document => ({
        ...document,
        status: documentStatuses[document.id] || document.status,
        progress: documentProgress[document.id] ?? document.progress,
      })) ?? [],
    [fetchDocumentsQuery.data, documentStatuses, documentProgress]
  );

  const groupedDocuments = useMemo(() => {
    const groups = documents.reduce((acc, document) => {
      const date = new Date(document.created_at);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const key = groupBy === GroupBy.Day ? `${year}-${month}-${day}` : `${year}-${month}`;

      if (!acc[key]) acc[key] = [];

      acc[key].push(document);

      return acc;
    }, {} as { [key: string]: TDocument[] });

    const formatter =
      groupBy === GroupBy.Day ? formatShortDayMonthYearDate : formatShortMonthYearDate;

    return Object.entries(groups).map(([date, _documents]) => ({
      title: formatter(new Date(date)),
      documents: _documents,
    }));
  }, [documents, groupBy]);

  const connectSSE = useCallback(() => {
    if (sseClientRef.current || !session?.access_token) return;

    sseClientRef.current = new SSE(`${environment.direct}/document/status-stream`, {
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      },
    });

    sseClientRef.current.addEventListener('message', (event: SSEvent) => {
      const { document_id, status, progress } = JSON.parse(event.data);

      if (!document_id) return;

      if (status) {
        setDocumentStatuses(prevStatuses => ({
          ...prevStatuses,
          [document_id]: status,
        }));
      }

      if (progress) {
        setDocumentProgress(prevProgress => ({
          ...prevProgress,
          [document_id]: progress,
        }));
      }
    });

    sseClientRef.current.addEventListener('error', (error: any) => {
      sseClientRef.current?.close();
      sseClientRef.current = null;
      // Attempt to reconnect after a short delay
      setTimeout(connectSSE, 5000);
    });

    sseClientRef.current.stream();
  }, [session?.access_token]);

  useEffect(() => {
    connectSSE();

    return () => {
      if (sseClientRef.current) {
        sseClientRef.current.close();
      }
      sseClientRef.current = null;
    };
  }, [connectSSE]);

  if (fetchDocumentsQuery.isError)
    return (
      <Redirect
        to={{
          pathname: Routes.login,
          state: { from: location },
        }}
      />
    );

  return (
    <FullHeightPage hasExtraTopPadding>
      <IonGrid>
        <IonRow>
          <S.IonCol
            sizeXl="8"
            sizeLg="8"
            offsetXl="2"
            offsetLg="2"
            sizeMd="10"
            offsetMd="1"
            sizeSm="12"
          >
            {fetchDocumentsQuery.isFetching && <DocumentListLoadingState />}

            {fetchDocumentsQuery.isSuccess && !documents.length && (
              <DocumentListEmptyState
                onFileSelect={onFileSelect}
                isFileUploading={uploadDocumentMutation.isPending}
              />
            )}

            {fetchDocumentsQuery.isSuccess && !!documents.length && (
              <>
                <IonRow>
                  <IonCol size="12">
                    <Subtitle icon={documentsOutline} />
                    <h1>You have {documents.length} docs.</h1>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12">
                    <LLMChatBubble isFullWidth>You can upload more documents.</LLMChatBubble>
                  </IonCol>

                  <IonCol size="12" sizeXl="12">
                    <DocumentUploadCard
                      title="HOA Documents"
                      description="Stay updated with community rules and bylaws"
                      isFileUploading={uploadDocumentMutation.isPending}
                      onFileSelect={onFileSelect}
                    />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12">
                    <Tabs>
                      <TabItem
                        isActive={groupBy === GroupBy.Month}
                        size="large"
                        onClick={() => setGroupBy(GroupBy.Month)}
                      >
                        By Month
                      </TabItem>
                      <TabItem
                        isActive={groupBy === GroupBy.Day}
                        size="large"
                        onClick={() => setGroupBy(GroupBy.Day)}
                      >
                        By Day
                      </TabItem>
                    </Tabs>
                  </IonCol>
                </IonRow>

                {groupedDocuments.map((group, index) => (
                  <IonRow key={group.title}>
                    <IonCol size="12">
                      <IonCard>
                        <IonList lines="full" inset>
                          <IonListHeader>
                            <IonNote>{group.title}</IonNote>
                          </IonListHeader>

                          {uploadDocumentMutation.isPending && index === 0 && (
                            <DocumentListItemLoadingState />
                          )}

                          {group.documents.map(document => (
                            <DocumentListItem
                              key={document.id}
                              document={document}
                              onDeleted={onDocumentDeleted}
                            />
                          ))}
                        </IonList>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                ))}
              </>
            )}
          </S.IonCol>
        </IonRow>
      </IonGrid>
    </FullHeightPage>
  );
};

export default React.memo(DocumentListPage);
