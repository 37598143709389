import { IonIcon, IonImg } from '@ionic/react';
import { thumbsDownOutline, thumbsDownSharp, thumbsUpOutline, thumbsUpSharp } from 'ionicons/icons';
import React, { forwardRef } from 'react';

import { BaseProps } from '../../types/props';

import S from './LLMChatBubble.styles';

export type Rating = 'positive' | 'negative';

type Props = {
  children: React.ReactNode;
  isFullWidth?: boolean;
} & (
  | {
      isRateable?: never;
      rating?: never;
      onRatingChange?: never;
    }
  | {
      isRateable: true;
      rating?: Rating;
      onRatingChange: (rating: Rating) => void;
    }
) &
  BaseProps;

const LLMChatBubble = forwardRef<HTMLDivElement, Props>(
  ({ children, isFullWidth = false, isRateable, rating, onRatingChange, ...baseProps }, ref) => (
    <S.Container {...baseProps} ref={ref}>
      <S.AvatarContainer>
        <IonImg src="./assets/images/terri-logo.svg" alt="Terri" />
      </S.AvatarContainer>

      <S.MessageContainer>
        <S.MessageWrapper $isFullWidth={isFullWidth}>
          {children}

          {isRateable && (
            <S.RatingContainer>
              <S.RatingIonButton
                onClick={() => onRatingChange('positive')}
                disabled={rating === 'positive'}
                size="small"
                color="neutral-300"
              >
                <IonIcon
                  slot="icon-only"
                  color="darkgray-300"
                  icon={rating === 'positive' ? thumbsUpSharp : thumbsUpOutline}
                />
              </S.RatingIonButton>

              <S.RatingIonButton
                onClick={() => onRatingChange('negative')}
                disabled={rating === 'negative'}
                size="small"
                color="neutral-300"
              >
                <IonIcon
                  slot="icon-only"
                  color="darkgray-300"
                  icon={rating === 'negative' ? thumbsDownSharp : thumbsDownOutline}
                />
              </S.RatingIonButton>
            </S.RatingContainer>
          )}
        </S.MessageWrapper>
      </S.MessageContainer>
    </S.Container>
  )
);

export default LLMChatBubble;
