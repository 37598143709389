import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Channel, DefaultGenerics } from 'stream-chat';

import { useAuth } from '../context/AuthProvider';

export const useAskQuestionFromLocationState = (
  channel: Channel<DefaultGenerics> | null,
  canSendMessage = true
) => {
  const questionToAsk = useRef<string | null>(window.history.state?.state?.question ?? null);

  const { user } = useAuth();
  const location = useLocation<{
    question?: string;
  }>();

  useEffect(() => {
    if (!user) return;

    if (!channel) return;

    if (!canSendMessage) return;

    if (!questionToAsk.current) return;

    channel.sendMessage({
      text: questionToAsk.current,
      user_id: user.id,
    });

    // Make sure to clear state,
    // so the question isn't asked again on reload.
    questionToAsk.current = null;
    window.history.replaceState({}, '');
  }, [user, channel, canSendMessage, questionToAsk]);

  useEffect(() => {
    if (!location?.state?.question) return;

    questionToAsk.current = location.state.question;
  }, [location.state]);
};
