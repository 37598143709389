import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { environment } from '@env';

import PropertyDetails from '../components/property/PropertyDetails';
import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';
import { UserProperty } from '../types/userProperty';

import FullHeightPage from './common/FullHeightPage';
import { CenteredContent } from './common/layouts.styles';

const DashboardPage: React.FC = () => {
  const { session } = useAuth();
  const location = useLocation();

  const getUserPropertyQuery = useQuery({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'user-property', session?.access_token],
    gcTime: 0,
    queryFn: async () => {
      const response = await axios.get<UserProperty>(`${environment.api}/user-property`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      return response.data;
    },
  });

  const property = getUserPropertyQuery.data?.Property;

  if (getUserPropertyQuery.isFetched && !property) return <Redirect to={Routes.propertyCreate} />;

  if (getUserPropertyQuery.isError)
    return (
      <Redirect
        to={{
          pathname: Routes.login,
          state: {
            from: location,
          },
        }}
      />
    );

  return (
    <FullHeightPage background="transparent">
      <CenteredContent>
        <PropertyDetails property={property} isLoading={!property} />
      </CenteredContent>
    </FullHeightPage>
  );
};

export default DashboardPage;
