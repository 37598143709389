import { type JSX } from '@ionic/core/components';
import { IonIcon, RouterDirection, RouterOptions } from '@ionic/react';
import React, { HTMLAttributes } from 'react';

import { BaseProps } from '../types/props';

import S from './CircleIconButton.styles';

type Props = BaseProps &
  JSX.IonButton &
  HTMLAttributes<HTMLIonButtonElement> & {
    routerLink?: string | undefined;
    routerDirection?: RouterDirection | undefined;
    routerOptions?: RouterOptions | undefined;
    icon: string | React.ReactNode;
    children?: never;
  };

const CircleIconButton: React.FC<Props> = ({ icon, ...props }) => (
  <S.IonButton {...props} shape="round">
    {typeof icon === 'string' ? <IonIcon slot="icon-only" icon={icon} /> : icon}
  </S.IonButton>
);

export default CircleIconButton;
