import { css } from 'styled-components';

const streamChatCss = css`
  .str-chat {
    /* Border radius used for slightly rounded elements */
    --str-chat__border-radius-xs: 8px;

    /* Border radius used for slightly rounded elements */
    --str-chat__border-radius-sm: 14px;

    /* Border radius used for rounded elements */
    --str-chat__border-radius-md: 18px;

    /* Border radius used for rounded elements */
    --str-chat__border-radius-lg: 20px;

    /* Border radius used for circular elements */
    --str-chat__border-radius-circle: 999px;

    /* The font used in the chat, by default, we use [preinstalled OS fonts](https://systemfontstack.com/) */
    --str-chat__font-family: 'Matter', sans-serif;

    /* The font used for caption texts */
    --str-chat__caption-text: 0.75rem/1.3 var(--str-chat__font-family);

    /* The font used for caption texts with emphasize */
    --str-chat__caption-medium-text: 500 0.75rem/1.3 var(--str-chat__font-family);

    /* The font used for caption texts with emphasize */
    --str-chat__caption-strong-text: 700 0.75rem/1.3 var(--str-chat__font-family);

    /* The font used for body texts */
    --str-chat__body-text: 0.875rem/1.2 var(--str-chat__font-family);

    /* The font used for body texts with emphasize */
    --str-chat__body-medium-text: 500 0.875rem/1.2 var(--str-chat__font-family);

    /* The font used for body texts */
    --str-chat__body2-text: 0.9375rem/1.2 var(--str-chat__font-family);

    /* The font used for body texts with emphasize */
    --str-chat__body2-medium-text: 500 0.9375rem/1.2 var(--str-chat__font-family);

    /* The font used for subtitle texts */
    --str-chat__subtitle-text: 1rem/1.1.25 var(--str-chat__font-family);

    /* The font used for subtitle texts with emphasize */
    --str-chat__subtitle-medium-text: 500 1rem/1.25 var(--str-chat__font-family);

    /* The font used for subtitle texts */
    --str-chat__subtitle2-text: 1.25rem/1.2 var(--str-chat__font-family);

    /* The font used for subtitle texts with emphasize */
    --str-chat__subtitle2-medium-text: 500 1.25rem/1.2 var(--str-chat__font-family);

    /* The font used for headline texts */
    --str-chat__headline-text: 1.5rem/1.2 var(--str-chat__font-family);

    /* The font used for headline texts */
    --str-chat__headline2-text: 1.8rem/1.2 var(--str-chat__font-family);
  }

  .str-chat,
  .str-chat__theme-light {
    /* Used for emphasis, brands can inject their main color using this variable */
    --str-chat__primary-color: var(--figma-color-darkgray-300);

    /* Used for emphasised overlays - color of --str-chat__primary-color with alpha channel */
    --str-chat__primary-overlay-color: var(--figma-color-darkgray-300-80);

    /* Used for emphasis, brands can inject their main color using this variable, it has less emphasis than primary color */
    --str-chat__primary-color-low-emphasis: var(--str-chat__blue300);
    --str-chat__primary-color-low-emphasis: var(--figma-color-red);

    /* Used to indicate that a UI element with primary color is in an active state */
    --str-chat__active-primary-color: var(--str-chat__blue600);
    --str-chat__active-primary-color: var(--figma-color-red);

    /* If the primary color is used as a background, text/icons are displayed in this color */
    --str-chat__on-primary-color: var(--figma-color-neutral-100);

    /* Used as a background color for the main chat UI components */
    --str-chat__background-color: transparent;

    /* Used as a background color for the main chat UI components */
    --str-chat__secondary-background-color: transparent;

    /* Used as a background color to give emphasis, but less vibrant than the primary color */
    --str-chat__primary-surface-color: rgba(var(--figma-color-ocean-8-rgb), 0.08);

    /* Used as a background color to give emphasis, but less vibrant than the primary surface color */
    --str-chat__primary-surface-color-low-emphasis: var(--str-chat__blue50);
    --str-chat__primary-surface-color-low-emphasis: var(--figma-color-red);

    /* A neutral color used to give emphasis to different surfaces */
    --str-chat__surface-color: var(--figma-color-darkgray-300);

    /* A neutral color used to give emphasis to different surfaces */
    --str-chat__secondary-surface-color: var(--figma-color-darkgray-100);

    /* A neutral color used to give emphasis to different surfaces */
    --str-chat__tertiary-surface-color: var(--str-chat__grey100);
    --str-chat__tertiary-surface-color: var(--figma-color-red);

    /* The main color used for texts/icons */
    --str-chat__text-color: var(--figma-color-darkgray-300);

    /* Used for texts/icons that need less emphasis */
    --str-chat__text-low-emphasis-color: var(--figma-color-darkgray-300);

    /* Used for displaying disabled UI elements (typically buttons) */
    --str-chat__disabled-color: var(--str-chat__grey400);
    --str-chat__disabled-color: var(--figma-color-neutral-300);

    /* Used for text/icon colors if disabled color is used as a background color */
    --str-chat__on-disabled-color: var(--str-chat__grey50);
    --str-chat__on-disabled-color: var(--figma-color-red);

    /* Used for error messages, and destructive actions */
    --str-chat__danger-color: var(--str-chat__red400);
    --str-chat__danger-color: var(--figma-color-red);

    /* The background color used to highlight a message when jumping to a message. Only available in React SDK. */
    --str-chat__message-highlight-color: var(--str-chat__yellow100);
    --str-chat__message-highlight-color: var(--figma-color-red);

    /* Used for displaying the unread badge */
    --str-chat__unread-badge-color: var(--str-chat__red400);
    --str-chat__unread-badge-color: var(--figma-color-red);

    /* Used for text/icon colors if unread badge color is used as a background color */
    --str-chat__on-unread-badge-color: var(--str-chat__grey50);
    --str-chat__on-unread-badge-color: var(--figma-color-red);

    /* The background color used for overlays */
    --str-chat__overlay-color: var(--figma-color-darkgray-300-80);

    /* The background color used for subtle overlays */
    --str-chat__secondary-overlay-color: var(--figma-color-darkgray-300);

    /* The text/icon color used on subtle overlays */
    --str-chat__secondary-overlay-text-color: var(--figma-color-neutral-200);

    /* The background color used for opaque surfaces */
    --str-chat__opaque-surface-background-color: rgba(0, 0, 0, 0.8);
    --str-chat__opaque-surface-background-color: var(--figma-color-red);

    /* The text color used on opaque surfaces */
    --str-chat__opaque-surface-text-color: var(--figma-color-neutral-100);

    /* If a component has a box shadow applied to it, this will be the color used for the shadow */
    --str-chat__box-shadow-color: rgba(0, 0, 0, 0.18);
    --str-chat__box-shadow-color: var(--figma-color-red);

    /* Used for online indicator and success messages */
    --str-chat__info-color: var(--str-chat__green500);
    --str-chat__info-color: var(--figma-color-red);
  }

  .str-chat__theme-dark {
    --str-chat__primary-color: var(--figma-color-neutral-100);
    --str-chat__primary-overlay-color: var(--figma-color-red);
    --str-chat__primary-color-low-emphasis: var(--figma-color-red);
    --str-chat__active-primary-color: var(--figma-color-red);
    --str-chat__on-primary-color: var(--figma-color-darkgray-300);
    --str-chat__background-color: transparent;
    --str-chat__secondary-background-color: transparent;
    --str-chat__primary-surface-color: var(--figma-color-red);
    --str-chat__primary-surface-color: var(--figma-color-darkgray-300);
    --str-chat__primary-surface-color-low-emphasis: var(--figma-color-red);
    --str-chat__surface-color: transparent;
    --str-chat__secondary-surface-color: var(--figma-color-red);
    --str-chat__tertiary-surface-color: var(--figma-color-red);
    --str-chat__text-color: var(--figma-color-neutral-100);
    --str-chat__text-low-emphasis-color: var(--figma-color-neutral-300);
    --str-chat__disabled-color: var(--figma-color-neutral-300);
    --str-chat__on-disabled-color: var(--figma-color-red);
    --str-chat__danger-color: var(--figma-color-red);
    --str-chat__message-highlight-color: var(--figma-color-red);
    --str-chat__unread-badge-color: var(--figma-color-red);
    --str-chat__on-unread-badge-color: var(--figma-color-red);
    --str-chat__overlay-color: var(--figma-color-neutral-100-80);
    --str-chat__secondary-overlay-color: var(--figma-color-neutral-100);
    --str-chat__secondary-overlay-text-color: var(--figma-color-darkgray-300);
    --str-chat__opaque-surface-background-color: var(--figma-color-red);
    --str-chat__opaque-surface-text-color: var(--figma-color-darkgray-300);
    --str-chat__box-shadow-color: var(--figma-color-red);
    --str-chat__info-color: var(--figma-color-red);
  }
`;

export default streamChatCss;
