import styled, { css } from 'styled-components';

import DocumentChatStyles from '../components/document/DocumentChat.styles';
import DocumentSummaryStyles from '../components/document/DocumentSummary.styles';
import PDFDocumentStyles from '../components/document/PDFDocument.styles';
import { from, to } from '../styles/breakpoints';

import FullHeightPage from './common/FullHeightPage';
import FullHeightPageStyles from './common/FullHeightPage.styles';

const StyledFullHeightPage = styled(FullHeightPage)`
  ${FullHeightPageStyles.MainContainer} {
    padding: 1rem 1rem 2rem 1rem;

    ${to.md} {
      padding: 1rem 0 0 0;
    }
  }
`;

const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex: 0 1 100%;
  max-height: 100%;
  max-width: 100%;

  ${to.md} {
    overflow: auto;
  }
`;

const SummaryContainer = styled.div<{ $isActive: boolean; $isCollapsed: boolean }>`
  flex: 0 0 23rem;

  ${to.md} {
    flex: 1 1 100%;

    ${DocumentSummaryStyles.IonCard} {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  ${({ $isActive }) =>
    !$isActive &&
    css`
      ${to.md} {
        display: none;
      }
    `}

  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      ${from.lg} {
        display: none;
      }
    `}
`;

const PDFContainer = styled.div<{ $isActive: boolean }>`
  flex: 0 1 100%;
  display: flex;
  overflow: auto;

  ${to.md} {
    flex: 1 1 100%;
  }

  ${({ $isActive }) =>
    !$isActive &&
    css`
      ${to.md} {
        display: none;
      }
    `}

  ${to.md} {
    ${PDFDocumentStyles.Container} {
      padding: 0 1rem 1rem 1rem;
    }

    ${PDFDocumentStyles.ControlsContainer} {
      max-width: calc(100% - 8rem);
      margin-left: auto;
      margin-right: auto;
    }
  }

  ${to.sm} {
    ${PDFDocumentStyles.ControlsContainer} {
      display: flex;
      justify-content: center;
    }

    ${PDFDocumentStyles.FileName} {
      display: none;
    }

    ${PDFDocumentStyles.Buttons} {
      display: none;
    }
  }
`;

const ChatContainer = styled.div<{ $isActive: boolean; $isExtended: boolean }>`
  flex: 0 0 23rem;

  ${({ $isExtended }) =>
    $isExtended &&
    css`
      flex: 0 0 50%;
    `}

  ${from.lg} {
    margin-bottom: -1.5rem;

    ${DocumentChatStyles.IonCard} {
      --ion-card-background: transparent !important;

      ion-card-content {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: var(--figma-color-darkgray-100);
    z-index: 1;
  }

  ${({ $isActive }) =>
    !$isActive &&
    css`
      ${to.md} {
        display: none;
      }
    `}

  ${to.md} {
    flex: 1 1 100%;

    &::before {
      display: none;
    }

    ${DocumentChatStyles.IonCard} {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

const MobileTopMenu = styled.div`
  display: none;

  ${to.md} {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const CloseButtonMenu = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
  ${to.md} {
    display: none;
  }
`;

const MobileChatButtonMenu = styled.div`
  display: none;

  ${to.md} {
    display: block;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 1;
  }
`;

const ExtendSummaryButtonMenu = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1;

  ${to.md} {
    display: none;
  }
`;

export default {
  FullHeightPage: StyledFullHeightPage,
  Container,
  SummaryContainer,
  PDFContainer,
  ChatContainer,
  MobileTopMenu,
  CloseButtonMenu,
  MobileChatButtonMenu,
  ExtendSummaryButtonMenu,
};
