import { IonLabel, IonNote } from '@ionic/react';
import styled from 'styled-components';

import { to } from '../../styles/breakpoints';

const StyledIonLabel = styled(IonLabel)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledIonNote = styled(IonNote)`
  ${to.md} {
    display: none;
  }
`;

export default {
  IonLabel: StyledIonLabel,
  IonNote: StyledIonNote,
};
