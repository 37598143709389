import { type JSX } from '@ionic/core/components';
import { HTMLAttributes } from 'react';

import { BaseProps } from '../../types/props';

import S from './TabItem.styles';

type Props = BaseProps &
  JSX.IonButton &
  HTMLAttributes<HTMLIonButtonElement> & {
    isActive: boolean;
  };

const TabItem: React.FC<Props> = ({ isActive, ...btnProps }) => (
  <S.IonButton {...btnProps} color={isActive ? 'neutral-300' : 'neutral-200'} />
);

export default TabItem;
