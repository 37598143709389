// eslint-disable-next-line no-shadow
export enum Routes {
  home = '/',
  // Public
  aboutUs = '/about-us',
  login = '/login',
  logout = '/logout',
  privacyPolicy = '/privacy-policy',
  termsOfService = '/terms-of-service',
  signup = '/signup',
  // Semi-public or Authenticated
  chat = '/chat',
  dashboard = '/dashboard',
  documentList = '/documents',
  documentView = '/documents/:id',
  propertyCreate = '/properties/add',
  propertyList = '/properties',
  propertyView = '/properties/:id',
}

// No chat
export const associaPublicRoutes: Routes[] = [
  Routes.home,
  Routes.aboutUs,
  Routes.login,
  Routes.logout,
  Routes.privacyPolicy,
  Routes.termsOfService,
  Routes.signup,
];

export const associaAuthenticatedRoutes = [
  ...associaPublicRoutes,
  Routes.documentList,
  Routes.documentView,
];

// With chat
// We need chat to be public, because
// we create the user within the flow.
export const publicRoutes = [
  Routes.home,
  Routes.aboutUs,
  Routes.login,
  Routes.logout,
  Routes.privacyPolicy,
  Routes.termsOfService,
  Routes.signup,
  Routes.chat,
];

export const anonymousUserRoutes = [...publicRoutes, Routes.dashboard];

export const authenticatedUserRoutes = [
  ...publicRoutes,
  Routes.dashboard,
  Routes.documentList,
  Routes.documentView,
  Routes.propertyList,
  Routes.propertyCreate,
  Routes.propertyView,
];
