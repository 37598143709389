import {
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonProgressBar,
} from '@ionic/react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { documentOutline } from 'ionicons/icons';
import React, { useCallback, useMemo } from 'react';

import { useAuth } from '../../context/AuthProvider';
import { TDocument } from '../../types/document';
import { BaseProps } from '../../types/props';
import { getFromNow } from '../../utils/date';
import { getDocumentStatus } from '../../utils/document';

import S from './DocumentListItem.styles';

interface Props extends BaseProps {
  document: TDocument;
  onDeleted?: (document: TDocument) => void;
}

const DocumentListItem: React.FC<Props> = ({ document, onDeleted, ...baseProps }) => {
  const { session } = useAuth();

  const deleteDocumentMutation = useMutation({
    mutationFn: async (documentId: string) => {
      const response = await axios.delete(`/api/document/${documentId}`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      return response.data;
    },
  });

  const status = useMemo(() => {
    if (deleteDocumentMutation.isPending) {
      return 'DELETING';
    }

    if (deleteDocumentMutation.isSuccess) {
      return 'DELETED';
    }

    return document.status;
  }, [deleteDocumentMutation.isPending, deleteDocumentMutation.isSuccess, document.status]);

  const isSlidingDisabled = ['PROCESSING', 'DELETING'].includes(status);
  const isDisabled = ['NEW', 'PROCESSING', 'DELETING', 'DELETED'].includes(status);
  const isLoading = useMemo(() => {
    if (status === 'DELETING') return true;

    if (Date.now() - new Date(document.created_at).getTime() > 15 * 60 * 1000) return false;

    if (status === 'PROCESSING') return true;

    return false;
  }, [document.created_at, status]);

  const onDelete = useCallback(async () => {
    await deleteDocumentMutation.mutateAsync(document.id);

    onDeleted?.(document);
  }, [document, deleteDocumentMutation, onDeleted]);

  if (status === 'DELETED') return null;

  return (
    <IonItemSliding id={`sliding-${document.id}`} disabled={isSlidingDisabled}>
      <IonItem disabled={isDisabled} routerLink={`/documents/${document.id}`} {...baseProps}>
        <IonIcon icon={documentOutline} size="large" slot="start" />

        <S.IonLabel>{document.original_name}</S.IonLabel>

        <S.IonNote slot="end">
          {getFromNow(new Date(document.created_at))} | {getDocumentStatus(status)}
        </S.IonNote>
      </IonItem>

      {isLoading && (
        <IonProgressBar
          slot="end"
          value={document.progress}
          type={document.progress ? 'determinate' : 'indeterminate'}
        />
      )}

      <IonItemOptions side="end" onIonSwipe={onDelete}>
        <IonItemOption color="danger" expandable disabled={isSlidingDisabled}>
          Delete
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
};

export default DocumentListItem;
