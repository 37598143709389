import { IonSpinner } from '@ionic/react';
import React, { useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import MainChatFlow from '../components/chat/MainChatFlow';
import { Routes } from '../config/routes';
import { useAuth } from '../context/AuthProvider';

import FullHeightPage from './common/FullHeightPage';
import { CenteredContent } from './common/layouts.styles';

export interface ChatRouteState {
  question?: string;
  isUnauthenticatedExperience?: boolean;
}

// eslint-disable-next-line no-shadow
enum AuthStatus {
  authenticated,
  loginRequired,
  processing,
  anonymous,
}

const ChatPage: React.FC = () => {
  const location = useLocation<ChatRouteState>();

  const { isAuthenticated, shouldHaveAuthToken } = useAuth();

  const shouldCreateAnonymousUser = useMemo(
    () => !isAuthenticated && location.state?.isUnauthenticatedExperience && !shouldHaveAuthToken,
    [isAuthenticated, location.state, shouldHaveAuthToken]
  );

  const authStatus: AuthStatus = useMemo(() => {
    if (isAuthenticated) return AuthStatus.authenticated;

    if (shouldHaveAuthToken) return AuthStatus.processing;

    if (location.state?.isUnauthenticatedExperience) return AuthStatus.anonymous;

    return AuthStatus.loginRequired;
  }, [isAuthenticated, shouldHaveAuthToken, location.state]);

  if (authStatus === AuthStatus.loginRequired)
    return (
      <Redirect
        to={{
          pathname: Routes.login,
          state: {
            from: location,
          },
        }}
      />
    );

  return (
    <FullHeightPage background="transparent">
      {authStatus === AuthStatus.processing ? (
        <CenteredContent>
          <IonSpinner name="circular" />
        </CenteredContent>
      ) : (
        <MainChatFlow shouldCreateAnonymousUser={shouldCreateAnonymousUser} />
      )}
    </FullHeightPage>
  );
};

export default ChatPage;
