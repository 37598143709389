import { IonAvatar } from '@ionic/react';
import styled from 'styled-components';

import { to } from '../../styles/breakpoints';

const Container = styled.div`
  display: grid;
  flex: 0 0 6.25rem;
  grid-template-rows: 1fr auto 1fr;
  grid-template-areas:
    'top'
    'center'
    'bottom';
  height: 100%;
  padding: 2rem 0;

  ${to.md} {
    padding: 0.5rem 1rem;
    flex-basis: 0;
    grid-template-rows: none;
    grid-template-columns: 0 3fr 0;
    grid-template-areas: 'top center bottom';
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  justify-self: center;
  align-items: center;
  width: 100%;
`;

const ContainerTop = styled(SubContainer)`
  grid-area: top;
  align-self: flex-start;

  ${to.md} {
    display: none;
  }
`;

const ContainerCenter = styled(SubContainer)`
  grid-area: center;
  align-self: center;
  gap: 0.5rem;

  ${to.md} {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const ContainerBottom = styled(SubContainer)`
  grid-area: bottom;
  align-self: flex-end;
`;

const StyledIonAvatar = styled(IonAvatar)`
  height: 3rem;
  width: 3rem;
  flex: 0 0 3rem;

  ${to.md} {
    display: none;
  }
`;

export default {
  Container,
  ContainerTop,
  ContainerCenter,
  ContainerBottom,
  IonAvatar: StyledIonAvatar,
};
