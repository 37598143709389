import { IonPage } from '@ionic/react';

import ContentGradient from '../../components/ContentGradient';
import SideMenu from '../../components/menu/SideMenu';
import { BaseProps } from '../../types/props';

import S from './FullHeightPage.styles';

export interface Props extends BaseProps {
  children: React.ReactNode;
  background?: 'transparent' | 'neutral-200' | 'darkgray-300-80';
  hasExtraTopPadding?: boolean;
}

const FullHeightPage: React.FC<Props> = ({
  children,
  background = 'neutral-200',
  hasExtraTopPadding = false,
  ...baseProps
}) => (
  <IonPage {...baseProps}>
    <S.PageContainer>
      <ContentGradient />

      <SideMenu />

      <S.MainContainer $background={background} $hasExtraTopPadding={hasExtraTopPadding}>
        {children}
      </S.MainContainer>
    </S.PageContainer>
  </IonPage>
);

export default FullHeightPage;
