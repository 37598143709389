import { IonSpinner } from '@ionic/react';
import * as Sentry from '@sentry/react';
import React, { useCallback } from 'react';
import { DefaultGenerics, StreamChat as TStreamChat } from 'stream-chat';
import { type Channel as TChannel } from 'stream-chat/dist/types/channel';
import { type Message } from 'stream-chat/dist/types/types';
import {
  Channel,
  Chat,
  defaultRenderMessages,
  MessageInput,
  MessageList,
  MessageRenderer,
  Window,
} from 'stream-chat-react';

import { VERSION } from '../../environments/version';
import { CenteredContent } from '../../pages/common/layouts.styles';
import { BaseProps } from '../../types/props';

import S from './StreamChat.styles';
import TerraiMessageUI from './TerraiMessageUI';
import TypingIndicator from './TypingIndicator';

interface Props extends BaseProps {
  client: TStreamChat<DefaultGenerics> | null;
  channel: TChannel<DefaultGenerics> | null;
  isLoading: boolean;
  isMessageListHidden?: boolean;
  isMessageInputHidden?: boolean;
  isMessageInputDisabled?: boolean;
  BeforeMessageListSlot?: React.JSX.Element;
  AfterMessageListSlot?: React.JSX.Element;
  theme?: 'light' | 'dark';
}

const StreamChat: React.FC<Props> = ({
  client,
  channel,
  isLoading,
  isMessageListHidden = false,
  isMessageInputHidden = false,
  isMessageInputDisabled = false,
  BeforeMessageListSlot,
  AfterMessageListSlot,
  theme = 'light',
  ...baseProps
}) => {
  const doSendMessageRequest = useCallback(
    async (cbChannel: TChannel<DefaultGenerics>, message: Message<DefaultGenerics>) => {
      const activeSpan = Sentry.getActiveSpan();
      const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined;

      // Create `sentry-trace` header
      const sentryTrace = rootSpan ? Sentry.spanToTraceHeader(rootSpan) : undefined;

      // Create `baggage` header
      const sentryBaggage = rootSpan ? Sentry.spanToBaggageHeader(rootSpan) : undefined;

      const modifiedMessage = {
        ...message,
        sentryTrace,
        sentryBaggage,
        terrai_env: VERSION.subenv,
      };

      // Send the message via Stream Chat
      return cbChannel.sendMessage(modifiedMessage);
    },
    []
  );

  const renderMessages: MessageRenderer = useCallback(
    options => {
      const renderedMessages = defaultRenderMessages(options);

      if (BeforeMessageListSlot) {
        renderedMessages.unshift(<li key="BeforeMessageListSlot">{BeforeMessageListSlot}</li>);
      }

      if (AfterMessageListSlot) {
        renderedMessages.push(<li key="AfterMessageListSlot">{AfterMessageListSlot}</li>);
      }

      renderedMessages.push(
        <li key="TypingIndicator">
          <TypingIndicator key="TypingIndicator" />
        </li>
      );

      return renderedMessages;
    },
    [BeforeMessageListSlot, AfterMessageListSlot]
  );

  if (isLoading || !client || !channel) {
    return (
      <CenteredContent>
        <IonSpinner name="circular" />
      </CenteredContent>
    );
  }

  return (
    <S.Container {...baseProps}>
      <Chat client={client} theme={`str-chat__theme-${theme}`}>
        <Channel
          channel={channel}
          doSendMessageRequest={doSendMessageRequest}
          TypingIndicator={() => null}
        >
          <Window>
            {!isMessageListHidden && (
              <MessageList
                Message={TerraiMessageUI}
                disableDateSeparator
                disableQuotedMessages
                hideNewMessageSeparator
                renderMessages={renderMessages}
              />
            )}

            {!isMessageInputHidden && (
              <MessageInput
                maxRows={4}
                grow
                disabled={isMessageInputDisabled}
                disableMentions
                focus
                noFiles
              />
            )}

            <S.MessageInputFooter>
              Do not include personal or sensitive information in your message.
            </S.MessageInputFooter>
          </Window>
        </Channel>
      </Chat>
    </S.Container>
  );
};

export default StreamChat;
